/* eslint-disable */
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export function CourseBreadcum({ seleted_item, courseData, addExistingModule = false }) {
const navigateField =   useNavigate();
const moduledispatcher = useDispatch();
  return (
    <>
      <Flex>
        <Breadcrumb
          width="100%"
          padding={5}
          spacing="8px"
          position="sticky"
          top="0px"
          backgroundColor={"white"}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage={seleted_item === "courselists"}>
            <BreadcrumbLink as={RouterLink} to="/courselists">
              List Course{" "}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage={seleted_item === "createcourse"}>
            <BreadcrumbLink as={RouterLink}  to="/createcourse">
              Create Course
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
       

        {courseData &&  (
          <>
           <Spacer />
          <Flex mt="4" mr="4">

            { addExistingModule && 
            <Button colorScheme="teal" onClick={()=>{
              

              navigateField("/allmoduleslists", {
                state: JSON.stringify(courseData),
              });


            }}>Add Existing Module</Button>
          }
            <Button colorScheme="teal" ml="10" onClick={()=>{
              
              moduledispatcher({type:"save_course_data",course_data: courseData })
              moduledispatcher({type:"store_module_data",module_data: null })
            

              navigateField("/createmodule", {
                state: JSON.stringify(courseData),
              });

              
            }}>
              Add New Module
            </Button>

            {
              ((window.location.pathname === "/allmoduleslists") || (window.location.pathname === "/createmodule")) && 
              <Button colorScheme="teal" ml="10" onClick={()=>{
  
                window.history.back();
              }}>
               Back
              </Button>
              
            }
          </Flex>
          </>
        )}
      </Flex>
    </>
  );
}
