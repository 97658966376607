/* eslint-disable */
export const constdata = {
     
    //"mainUrl_2":"https://orionedutech.co.in/apis/learnhat_two/live-04-02-2022/",
    //"mainUrl":"https://orionedutech.co.in/apis/learnhat_two_dev/"

  "mainUrl":"https://orionedutech.co.in/apis/learnhat_two/"
   


}