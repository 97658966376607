/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";

import { CourseBreadcum } from "./CourseBreadcum";
import {
  loadCourseLists,
  inactiVeCourse,
  deleteCourse,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlertDialog } from "../../component/Customalert.dialog";
import { useDispatch, useSelector } from "react-redux";


export function Listscourses() {
  const perPageConstant = 50;
  const [courseLsists, setcourseLsists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
  const [showdialog, setshowdialog] = useState(false);
  const [indexCourse, setindexCourse] = useState(-1);
  const storeDispacher = useDispatch();

  const accessLabel = useSelector((state) => state.user_access);
  

  const toast = useToast();

  const navigate = useNavigate();

  const cancelAlert = () => {
    setshowdialog(!showdialog);
  };

  const deleteAlert = () => {
    setshowdialog(!showdialog);
    let temp_array = [...courseLsists];
    console.log(indexCourse);
    const returnResponse = deleteCourse(
      setcourseLsists,
      temp_array,
      indexCourse
    );

    if (returnResponse) {
      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Course deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  function searchBytext(item) {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) ||
      searchPattern.test(item.courseAuthor) ||
      searchPattern.test(item.sectorName) ||
      searchPattern.test(item.courseTypetwo) ||
      searchPattern.test(item.courseCode) 

      
    );
  }

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  useEffect(() => {
    // await
    loadCourseLists(setcourseLsists, searchText);
    // setcourseLsistsPagination(courseLsists.slice(pageStart, perPage));
  }, []);

  return (
    <>
      <Flex flexDirection={"columns"}>
        <CourseBreadcum seleted_item="courselists" />
        <Spacer/>
        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setcourseLsists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <CustomAlertDialog
          show={showdialog}
          cancelAlert={cancelAlert}
          deleteAlert={deleteAlert}
        />
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Code</Th>
              <Th>Name</Th>
              <Th>Author</Th>
              <Th>Category</Th>
              <Th>Type</Th>
              <Th>Price</Th>
              <Th>Students Enrolled</Th>
              <Th>Curriculum</Th>
              <Th>Student Feedback</Th>
              <Th>Feedback Count</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(courseLsists.length) >= 1 &&
              courseLsists.filter(searchBytext).map((element) => {

               
                
                return (
                <Tr key={element.id}>
                  <Td>
                    <Text>{element.courseCode}</Text>
                  </Td>
                  <Td>
                    <Text>{element.name}</Text>
                  </Td>
                  <Td>
                    <Text>{element.courseAuthor}</Text>
                  </Td>
                  <Td>
                    <Text>{element.sectorName}</Text>
                  </Td>
                  <Td>
                    <Text>{element.courseTypetwo}</Text>
                  </Td>
                  <Td>
                    <Text>{element.courseFee ? element.courseFee : "0"}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {element.studentEnrolled === null
                        ? "0"
                        : element.studentEnrolled}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      Modules : {element.module_count} <br /> Lectures : {element.lecture_count}
                      <br />
                      {`Total Length: ${
                        element.courseDurationHrs
                          ? element.courseDurationHrs
                          : "0"
                      }h ${
                        element.courseDurationMins
                          ? element.courseDurationMins
                          : "0"
                      }m`}
                    </Text>
                  </Td>
                  <Td>
                    <Flex direction={"rows"}>
                      <Text pl={"6px;"}>
                       
                        {element.fiveStar ? `${element.fiveStar}%` : "0%"}
                      </Text>
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                    </Flex>

                    <br />

                    <Flex direction={"rows"}>
                      <Text pl={"6px;"}>
                       
                        {element.fourStar ? `${element.fourStar}%` : "0%"}
                      </Text>
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                    </Flex>

                    <br />

                    <Flex direction={"rows"}>
                      <Text pl={"6px;"}>
                      
                        {element.threeStar ? `${element.threeStar}%` : "0%"}
                      </Text>
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                    </Flex>

                    <br />

                    <Flex direction={"rows"}>
                      <Text pl={"6px;"}>
                        {element.twoStar ? `${element.twoStar}%` : "0%"}
                      </Text>
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                    </Flex>

                    <br />

                    <Flex direction={"rows"}>
                      <Text pl={"6px;"}>
                        {element.oneStar ? `${element.oneStar}%` : "0%"}
                      </Text>
                      <Box as={StarIcon} size="32px" color="yellow.300" />
                    </Flex>
                  </Td>
                  <Td>
                    <Text>
                      {element.courseRating ? `${element.courseRating}` : "0"}
                    </Text>
                  </Td>
                  <Td>
                    <HStack mt={"10px"}>

                    {accessLabel !== null &&
                          accessLabel["pre_recorded_course"] !== null &&
                          accessLabel["pre_recorded_course"]["editPriv"] !== null &&
                          accessLabel["pre_recorded_course"]["editPriv"] === "1" && (
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Edit Course"
                        icon={<EditIcon />}
                        onClick={() => {
                          if (true) {
                            const array_position = findSeletedItemPosition(
                              courseLsists,
                              element
                            );
                            const data_object = courseLsists[array_position];
                            
                            storeDispacher({type:"save_course_data",course_data:data_object});
                            
                            navigate("/createcourse", {
                              state: JSON.stringify(data_object),
                            });
                          }
                        }}
                        // mx={2}
                      />
                          )}


{accessLabel !== null &&
                          accessLabel["pre_recorded_course"] !== null &&
                          accessLabel["pre_recorded_course"]["deletePriv"] !== null &&
                          accessLabel["pre_recorded_course"]["deletePriv"] === "1" && (

                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Delete Course"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          if (true) {
                            const array_position = findSeletedItemPosition(
                              courseLsists,
                              element
                            );

                            setindexCourse(array_position);
                            setshowdialog(!showdialog);
                          }
                        }}
                        // mx={2}
                      />
                          )}

                          
{accessLabel !== null &&
                          accessLabel["pre_recorded_course"] !== null &&
                          accessLabel["pre_recorded_course"]["disablePriv"] !== null &&
                          accessLabel["pre_recorded_course"]["disablePriv"] === "1" && (
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Inactive Course"
                        onClick={() => {
                          let temp_array = [...courseLsists];
                          const array_position = findSeletedItemPosition(
                            temp_array,
                            element
                          );
                          const returnResponse = inactiVeCourse(
                            setcourseLsists,
                            temp_array,
                            array_position
                          );
                          if (returnResponse) {
                            toast({
                              position: "bottom-left",
                              title: "Notification",
                              description:
                                Number.parseInt(element.isActive) === 1
                                  ? "Course activated successfully"
                                  : " Course Inactivated successfully.",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            });
                          }
                        }}
                        icon={
                          Number.parseInt(element.isActive) === 1 ? (
                            <ViewOffIcon />
                          ) : (
                            <ViewIcon />
                          )
                        }
                        // mx={2}
                      />

                          )}

                      
                    </HStack>
                  </Td>
                </Tr>
              )})}
          </Tbody>
        </Table>
      </Box>

      {(courseLsists.filter(searchBytext).length > perPageConstant) & false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={courseLsists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
}

export default Listscourses;
