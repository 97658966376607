/* eslint-disable */
import { useLoginContext } from "../../context/Logincontextprovider";
import { ReactNode, useEffect, useReducer, useState } from "react";
import { Box, Button, Flex, Image, Select, toast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  Text,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { CourseBreadcum } from "./CourseBreadcum";
import { useLocation } from "react-router-dom";
import {
  upDateCourseHandler,
  createCourse,
  loadSectors,
  loadCourseTypeHandler,
} from "../../Helper/Courselists.helper";
import Listsmodules from "../CreateModule/Listsmodules";
import { Select as MultiSelect } from "chakra-react-select";

export const Createcourse = () => {
  //const {element} =  useLocation();
  const { state } = useLocation();
  const courseData = state ? JSON.parse(state) : null;

  const handleChangeforSector = (newValue, actionMeta) => {
  
    loginFromStateDispacher({
      type: "UPDATE_SECTOR",
      payload: { courseSector: newValue },
    });
  };

  const toast = useToast();

  const createCourseForms = {
    courseId: courseData ? courseData.id : "0",
    courseName: courseData ? courseData.name : "",
    courseDescription: courseData ? courseData.courseDescription : "",
    courseSector: courseData ? courseData.sectorId : "",
    courseImage: courseData ? courseData.courseImage : "",
    isPaid: courseData ? courseData.isPaid : "",
    courseFee: courseData ? courseData.courseFee : "0",
    courseDurationHrs: courseData ? courseData.courseDurationHrs : "0",
    courseDurationMins: courseData ? courseData.courseDurationMins : "0",
    courseAuthor: courseData ? courseData.courseAuthor : "",
    studentEnrolled: courseData ? courseData.studentEnrolled : "",
    courseType: courseData ? courseData.courseType : "0",

    isAdmFormReqd: courseData ? courseData.isAdmFormReqd : "0",
    isEmiApplicable: courseData ? courseData.isEmiApplicable : "0",

    courseImageFile: "",
  };

  const useReducerCreateCourse = (state, action) => {
    switch (action.type) {
      case "UPDATE_COURSENAME":
        return { ...state, courseName: action.payload.courseName };
      case "UPDATE_COURSEDESCRIPTION":
        return {
          ...action.payload.loginFromState,
        };
      case "UPDATE_SECTOR":
        return {
          ...state,
          courseSector: action.payload.courseSector,
        };
        case "UPDATE_isAdmFormReqd":
        return {
          ...state,
          isAdmFormReqd: action.payload.isAdmFormReqd,
        };
        case "UPDATE_isEmiApplicable":
        return {
          ...state,
          isEmiApplicable: action.payload.isEmiApplicable,
        };

      case "UPDATE_TYPE":
        return {
          ...state,
          courseType: action.payload.courseType,
        };
      case "UPDATE_SELECT_FILE":
        return {
          ...state,
          courseImageFile: action.payload.courseImageFile,
        };
      default:
        return { ...state };
    }
  };
  const [loginFromState, loginFromStateDispacher] = useReducer(
    useReducerCreateCourse,
    createCourseForms
  );

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(values) {
    return new Promise((resolve) => {
      // console.log(post_data);


      if(loginFromState.courseSector.length===0){

        toast({
          position: "bottom-left",
          title: "Notification",
          description: "Please select course category.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });

        reset();

        return false;
      }

      if (courseData) {
        const returnval = upDateCourseHandler(loginFromState, reset);
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Course  successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        const returnval = createCourse(loginFromState, reset);

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Course successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  }

  const [sectorlists, setsectorlists] = useState([{
      sector_lists:[],
      seleted_sector_lists:[],

  }]);
  const [selectedsectorlists, setSelectedsectorlists] = useState([]);
 
  const [courseType, setCourseType] = useState([]);
  const [addExistingModule, setaddExistingModule] = useState(false);

  useEffect(() => {
    const letcourseType = [...courseType];
    letcourseType.splice(0, 1);

    setCourseType(letcourseType);

    //setSelectedsectorlists(sectorlists.seleted_sector_lists);

    

   

   // console.log("Course Lists Loaded");
  }, [sectorlists]);

  useEffect(() => {
   
    loadCourseTypeHandler(setCourseType);

    if (courseData && Number(courseData.id) >= 1) {
      setaddExistingModule(
        Number(loginFromState.courseType) === 2 ||
          Number(loginFromState.courseType) === 1
          ? false
          : true
      );
    }
  }, []);

  useEffect(()=>{
    loadSectors(setsectorlists, courseData);
  },[]);

  
  const [multiselectreload,setMultiselectreload] = useState(false)

  useEffect(()=>{
  
  setMultiselectreload(!multiselectreload);
  if(sectorlists.seleted_sector_lists!=null){
    loginFromStateDispacher({
      type: "UPDATE_SECTOR",
      payload: { courseSector: sectorlists.seleted_sector_lists },
    });
  }


   
  },[sectorlists.seleted_sector_lists]);

  

  

  return (
    <>
      <CourseBreadcum
        seleted_item="createcourse"
        courseData={courseData}
        addExistingModule={addExistingModule}
      />

      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={"hidden"}
            name="course_id"
            value={courseData ? courseData.id : "0"}
          />
          <Flex>
            <FormControl isInvalid={errors.course_name} m={"10px"}>
              <FormLabel htmlFor="course_name">Course Name</FormLabel>
              <Input
                id="course_name"
                placeholder="Course Name"
                {...register("course_name", {
                  required: "Please enter course name",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                name="course_name"
                onChange={(event) => {
                  loginFromStateDispacher({
                    type: "UPDATE_COURSENAME",
                    payload: { courseName: event.target.value },
                  });
                }}
                value={loginFromState.courseName}
              />
              <FormErrorMessage>
                {errors.course_name && errors.course_name.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex>
            <FormControl isInvalid={errors.course_description} m={"10px"}>
              <FormLabel htmlFor="course_description">
                Course Description
              </FormLabel>
              <Textarea
                id="course_description"
                placeholder="Course Description"
                {...register("course_description", {
                  required: "Please enter course description",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                name="course_description"
                onChange={(event) => {
                  loginFromState.courseDescription = event.target.value;
                  loginFromStateDispacher({
                    type: "UPDATE_COURSEDESCRIPTION",
                    payload: { loginFromState: loginFromState },
                  });
                }}
                value={loginFromState.courseDescription}
              />
              <FormErrorMessage>
                {errors.course_description && errors.course_description.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"}>
            <FormControl isInvalid={errors.course_author} m={"10px"}>
              <FormLabel htmlFor="course_author">Course Author</FormLabel>
              <Input
                id="course_author"
                name="course_author"
                placeholder="Course Author"
                {...register("course_author", {
                  required: "Please enter author name",

                  minLength: {
                    value: 2,
                    message: "Please enter author name",
                  },
                })}
                onChange={(event) => {
                  loginFromState.courseAuthor = event.target.value;
                  loginFromStateDispacher({
                    type: "UPDATE_COURSEDESCRIPTION",
                    payload: { loginFromState: loginFromState },
                  });
                }}
                value={
                  loginFromState.courseAuthor === null
                    ? ""
                    : loginFromState.courseAuthor
                }
              />

              <FormErrorMessage>
                {errors.course_author && errors.course_author.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.course_price} m={"10px"}>
              <FormLabel htmlFor="course_price">Course Price</FormLabel>
              <Input
                id="course_price"
                placeholder="Course Price"
                {...register("course_price", {
                  required: "Please enter course price",

                  minLength: {
                    value: 1,
                    message: "Minimum length should be 0 or greater than 0",
                  },
                })}
                name="course_price"
                onChange={(event) => {
                  //  console.log(event.target.value.length);

                  if (!isNaN(event.target.value)) {
                    loginFromState.courseFee = event.target.value;
                    loginFromStateDispacher({
                      type: "UPDATE_COURSEDESCRIPTION",
                      payload: { loginFromState: loginFromState },
                    });
                  }
                }}
                value={
                  loginFromState.courseFee === null
                    ? "0"
                    : loginFromState.courseFee
                }
              />

              <FormErrorMessage>
                {errors.course_price && errors.course_price.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.course_price} m={"10px"}>
              <FormLabel htmlFor="student_enrolled">Student enrolled</FormLabel>
              <Input
                id="student_enrolled"
                placeholder="Student enrolled"
                {...register("student_enrolled", {
                  required: "Please enter student enrolled",

                  minLength: {
                    value: 1,
                    message: "Minimum length should be 0 or greater than 0",
                  },
                })}
                name="student_enrolled"
                onChange={(event) => {
                  //  console.log(event.target.value.length);

                  if (!isNaN(event.target.value)) {
                    loginFromState.studentEnrolled = event.target.value;
                    loginFromStateDispacher({
                      type: "UPDATE_COURSEDESCRIPTION",
                      payload: { loginFromState: loginFromState },
                    });
                  }
                }}
                value={
                  loginFromState.studentEnrolled === null
                    ? "0"
                    : loginFromState.studentEnrolled
                }
              />

              <FormErrorMessage>
                {errors.student_enrolled && errors.student_enrolled.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"}>
            <FormControl isInvalid={errors.course_duration_hour} m={"10px"}>
              <FormLabel htmlFor="course_duration_hour">
                Course Duration (In Hour )
              </FormLabel>
              <Input
                id="course_duration_hour"
                name="course_duration_hour"
                placeholder="Course Duration"
                {...register("course_duration_hour", {
                  required: "Please enter course duration",

                  minLength: {
                    value: 1,
                    message: "Minimum length should be 0 or greater than 0",
                  },
                })}
                onChange={(event) => {
                  //  console.log(event.target.value.length);

                  if (!isNaN(event.target.value)) {
                    loginFromState.courseDurationHrs = event.target.value;
                    loginFromStateDispacher({
                      type: "UPDATE_COURSEDESCRIPTION",
                      payload: { loginFromState: loginFromState },
                    });
                  }
                }}
                value={
                  loginFromState.courseDurationHrs === null
                    ? "0"
                    : loginFromState.courseDurationHrs
                }
              />

              <FormErrorMessage>
                {errors.course_duration_hour &&
                  errors.course_duration_hour.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.course_duration_minutes} m={"10px"}>
              <FormLabel htmlFor="course_duration_minutes">
                Course Duration (In Minute )
              </FormLabel>
              <Input
                id="course_duration_minutes"
                placeholder="Course Duration (In Minute)"
                {...register("course_duration_minutes", {
                  required: "Please enter course duration",

                  minLength: {
                    value: 1,
                    message: "Minimum length should be 0 or greater than 0",
                  },
                })}
                name="course_duration_minutes"
                onChange={(event) => {
                  //  console.log(event.target.value.length);

                  if (!isNaN(event.target.value)) {
                    loginFromState.courseDurationMins = event.target.value;
                    loginFromStateDispacher({
                      type: "UPDATE_COURSEDESCRIPTION",
                      payload: { loginFromState: loginFromState },
                    });
                  }
                }}
                value={
                  loginFromState.courseDurationMins === null
                    ? "0"
                    : loginFromState.courseDurationMins
                }
              />

              <FormErrorMessage>
                {errors.course_duration_minutes &&
                  errors.course_duration_minutes.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.course_select_sector} m={"10px"}>
              <FormLabel htmlFor="course_select_sector">
                Category Lists
              </FormLabel>

              

             
             {
               multiselectreload || !multiselectreload && 
               <MultiSelect
               isMulti
               name="colors"
               options={sectorlists.sector_lists}
               defaultValue={sectorlists.seleted_sector_lists}
               onChange={handleChangeforSector}
               closeMenuOnSelect={false}
               
             ></MultiSelect>
             }

             

              <FormErrorMessage>
                {errors.course_select_sector &&
                  errors.course_select_sector.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.course_select_tyep} m={"10px"}>
              <FormLabel htmlFor="course_select_tyep">Course Type</FormLabel>
              <Select
                key="seletc3"
                name="course_select_tyep"
                isDisabled={courseData && courseData.id ? true : false}
                placeholder="Select Course Type"
                {...register("course_select_tyep", {
                  required: courseData && courseData.id ? false : "Please select course type",
                 
                })}
              
                onChange={(event) => {
                  
                  setaddExistingModule(
                    Number(event.target.value) === 2 ? false : true
                  );
                  loginFromStateDispacher({
                    type: "UPDATE_TYPE",
                    payload: { courseType: event.target.value },
                  });
                }}
                value={loginFromState.courseType}
              >
                {courseType.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.courseType}
                    </option>
                  );
                })}
              </Select>

              <FormErrorMessage>
                {errors.course_select_tyep && errors.course_select_tyep.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"} ml={"10px"} mt={"10px"} mb={"10px"}>
          <FormControl >
            

          <Checkbox  colorScheme='green' defaultChecked={ Number.parseInt(loginFromState.isAdmFormReqd)===1?true:false }  onChange={(e) =>{

loginFromStateDispacher({
  type: "UPDATE_isAdmFormReqd",
  payload: { isAdmFormReqd: e.target.checked?1:0 },
});

          } } >
            Admission From Requred
          </Checkbox>

          <Checkbox  colorScheme='green' defaultChecked={ Number.parseInt(loginFromState.isEmiApplicable)===1?true:false }  ml={"10px"} onChange={(e) =>{

loginFromStateDispacher({
  type: "UPDATE_isEmiApplicable",
  payload: { isEmiApplicable: e.target.checked?1:0 },
});

          } }>
            Emi Applicable
          </Checkbox>
          

          </FormControl>

         

          </Flex>

          <Flex flexDirection={"row"}>
            {loginFromState.courseImage ? (
              <FormControl m={"10px"}>
                <FormLabel>Uploaded Course Picture</FormLabel>

                {loginFromState.courseImage.length >= 1 ? (
                  <Image src={loginFromState.courseImage} h={"120px"} />
                ) : (
                  <Text></Text>
                )}
              </FormControl>
            ) : (
              <Text></Text>
            )}

            <FormControl isInvalid={errors.course_description} m={"10px"}>
              <FormLabel htmlFor="upload_course_picture">
                Course Picture
              </FormLabel>

              <input
                type="file"
                name="upload_course_picture"
                onChange={(event) => {
                  loginFromStateDispacher({
                    type: "UPDATE_SELECT_FILE",
                    payload: { courseImageFile: event.target.files[0] },
                  });
                }}
              />
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"}>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>

      <Box>{courseData && <Listsmodules courseData={courseData} />}</Box>
    </>
  );
};

export default Createcourse;
