/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";

import Moment from "react-moment";

import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { fetchCertificateLists } from "../../Helper/Courselists.helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManageCertificateBreadCome } from "./ManageCertificateBreadCome";

export const Managecertificatelists = () => {
  const [certificatelists, setCertificateLists] = useState([]);
  const [searchBytext, setsearchBytext] = useState("");
  const storeDispacher = useDispatch();
  const toast = useToast();
  const accessLabel = useSelector((state) => state.user_access);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCertificateLists(setCertificateLists);
  }, []);


  
  useEffect(() => {
    console.log(certificatelists);
   // fetchCertificateLists(setCertificateLists);
  }, [certificatelists]);


  return (
    <>


<ManageCertificateBreadCome />
     
     
      <Box p={4}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Certificate Name</Th>
              <Th>Certificate Description</Th>
              <Th>Creation Date</Th>
              <Th>Assigned Courses Count</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {certificatelists !== undefined &&
              Number.parseInt(certificatelists.length) >= 1 &&
              certificatelists.map((element, array_index) => {
                return (
                  <Tr key={array_index}>
                    <Td>
                      <Text>{element.templateName}</Text>
                    </Td>
                    <Td>
                    <Text>{element.templateDesc}</Text>
                    </Td>


                    <Td>
                    <Text> <Moment format="DD/MM/YYYY">
                    {element.createDate}
                    </Moment>
                   </Text>
                    </Td>

                    <Td>
                    0{ false && element.templateDesc}
                   
                    </Td>
                   
                    <Td>
                     
                    <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Edit Certificate"
                              icon={<EditIcon />}
                              onClick={() => {
                                // storeDispacher()

                                storeDispacher({
                                  type: "certificateData",
                                  certificateData: element,
                                });

                                navigate("/editcertificate", {
                                  state: "",
                                });
                              }}
                              // mx={2}
                            />
                   
                    </Td>

                   </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
