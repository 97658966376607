/* eslint-disable */
import axios from "axios";
import { constdata } from "../constant/Constants";


//const { isUserLogin } = useLoginContext();




export const timeDistance = (date1, date2) => {
  let distance = Math.abs(date1 - date2);
  const hours = Math.floor(distance / 3600000);
  distance -= hours * 3600000;
  const minutes = Math.floor(distance / 60000);
  distance -= minutes * 60000;
  const seconds = Math.floor(distance / 1000);
  return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
};

export const findSeletedItemPosition = (temp_array, element) => {
  let array_position = 0;

  temp_array.filter((el, ind) => {
    if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
      array_position = ind;
    }
  });

  return array_position;
};


export const loadUserCourseLists = async (setmoduleAndCourseLists,courseData) =>{
  const meeting_id = courseData?courseData.meeting_id:"0";
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    meeting_id: meeting_id,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCourseForCreateMeeting",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    

     if(meeting_id==="0"){

      setmoduleAndCourseLists((oldData)=> {
       return {
        ...oldData,
        meetingModuleLists: response.data.data_set.module_lists_array,
        meetingLessionLists:  response.data.data_set.lession_lists_array,
        courselists:response.data.data_set.course_lists_array,
      }
      
    });
       

     }else{

      setmoduleAndCourseLists({
        meetingModuleLists: response.data.data_set.module_lists_array,
        meetingLessionLists:  response.data.data_set.lession_lists_array,
        courselists:response.data.data_set.course_lists_array,
        studentlists:response.data.data_set.student_lists,
      });


     }
   
  //  setMeetingCourseLists(response.data.data_set.course_lists_array);

    return true;
    
    //setcourseLsists(response.data);
  } catch (err) {
    return false;
  } finally {
    return true;
  }

}








export const fetchCertificatePrefixMenu = async (setcertificatePrefix) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCertificateMnemonic",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    
   // console.log("Data Recived 3");
    //console.log(response.data.certificateLists);

    setcertificatePrefix(response.data.certificateLists);

  } catch (err) {
  } finally {
  }

}



export const fetchGetCertificateCourse = async (setcourseLists) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCourses",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    
    const newArr = []
    response.data.map((val)=>{
      newArr.push({value:val.id,label:val.name})
    })


    setcourseLists(newArr);

  } catch (err) {
  } finally {
  }

}



export const fetchAssignedCourses = async (setseletedCertificate,certificateId) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    certificateId: certificateId,

  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/fetchAssignCourses",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    
    const newArr = []
    response.data.certificateCourseLists.map((val)=>{
      newArr.push({value:val.id,label:val.name})
    })


    setseletedCertificate(newArr);

  } catch (err) {
  } finally {
  }

}






export const fetchCertificateTemPlate = async (setcertificateTemplates) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCertificateTemplate",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    

    setcertificateTemplates(response.data.certificateLists);

  } catch (err) {
  } finally {
  }

}




export const fetchUserNotAdmin = async (setStudentLists) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getUserNotAdmins",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    

    console.log("Data Recived 2");
    console.log(response.data.student_lists);
    setStudentLists(response.data.student_lists);
    
    
   // console.log(response.data.student_lists);
  // setStudentLists(response.data.student_lists);
    
    //setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }

}





export const fetchCertificateLists = async (fetchCertificateLists) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCertificateLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    

    console.log("Data Recived 2");
    console.log(response.data.responseData);
    fetchCertificateLists(response.data.responseData);
    
    
   // console.log(response.data.student_lists);
  // setStudentLists(response.data.student_lists);
    
    //setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }

}



export const loadStudentForCreateMeetins = async (setStudentLists) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getStudentListsForCreateMeeting",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    
    
   // console.log(response.data.student_lists);
   setStudentLists(response.data.student_lists);
    
    //setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }

}




export const loadLessionForModule = async (setListsLession,lessonType,props) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_type: lessonType,
    moduleId:props.moduleId,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getLessonListsforModule",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );

    
    
    console.log(response.data);
    props.updateModulelessionLists(response.data.module_lesson_lists);
   
    setListsLession(response.data.lesson_lists);
    
    //setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }

}



export const loadModules = async (setcourseLsists, course_id) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_id: course_id,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getModules",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }
};

export const loadAllModules = async (setcourseLsists, course_id) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_id: course_id,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getAllModules",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }
};




export const loadLivemeetings = async (setcourseLsists, searchText) => {
  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getMeetingLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    console.log(response.data)
    setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }
};



export const loadLessionLists = async (setcourseLsists, searchText) => {
  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getlessionLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    console.log(response.data)
    setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }
};



export const loadPaymentDetails = async (setcourseLsists, searchText) => {
  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getPaymentRecords",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    console.log(response.data)
    setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }
};


export const loadCourseLists = async (setcourseLsists, searchText) => {
  
  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCourses",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    setcourseLsists(response.data);
  } catch (err) {
  } finally {
  }
};

export const loadSectors = async (setsectorlists,courseData) => {
  const getCourseJsonLists = { token: localStorage.getItem("session_token"),courseID:courseData ? courseData.id : "0" };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getSectors",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
   

      setsectorlists((oldState) => {
        return {
          ...oldState,
          sector_lists: response.data.sector_lists,
          seleted_sector_lists: response.data.course_sector_lists,
        };
      });
    
   
  } catch (err) {
  } finally {
  }
};


export const loadCourseTypeHandler = async (setCourseType) => {
  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getCourseType",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    setCourseType(response.data);
  } catch (err) {
  } finally {
  }
};


export const deleteMeeting = async (
  setcourseLsists,
  temp_array,
  array_position
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    meeting_pk: temp_array[array_position].meeting_id,
    active_status: temp_array[array_position].isDeleted,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/deleteMeeting",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      
      temp_array.splice(array_position, 1);
      setcourseLsists(temp_array);
      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};



export const deleteLesson = async (
  setcourseLsists,
  temp_array,
  array_position
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    LessonId: temp_array[array_position].id,
    deletedStatus: temp_array[array_position].isDeleted,
  };
  let response = "";

 
  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/deleteLesson",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      
      temp_array.splice(array_position, 1);
      setcourseLsists(temp_array);
      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};


export const inactiVeMeeting = async (
  setcourseLsists,
  temp_array,
  array_position
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    meeting_pk: temp_array[array_position].meeting_id,
    active_status: temp_array[array_position].isActive,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/activeInactiveMeeting",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      
      temp_array[array_position].isActive = response.data.active_status;
      setcourseLsists(temp_array);
      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};


export const inactiVeLesson = async (
  setcourseLsists,
  temp_array,
  array_position
) => {


  
  

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    lessonId: temp_array[array_position].id,
    lessonisActive: temp_array[array_position].isActive,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/activeInactivelesson",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      
      temp_array[array_position].isActive = response.data.active_status;
      setcourseLsists(temp_array);
      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const inactiVeCourse = async (
  setcourseLsists,
  temp_array,
  array_position
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_id: temp_array[array_position].id,
    active_status: temp_array[array_position].isActive,
  };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/activeInactiveCourse",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      temp_array[array_position].isActive = response.data.active_status;
      setcourseLsists(temp_array);
      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const deleteModuleFromCourse = async (
  moduleLists,
  setModuleLists,
  course_id,
  module_id,
  array_position
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_id: course_id,
    module_id: module_id,
  };

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/deleteModuleFromCourse",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.deleted_status) {
      const temp_array = [...moduleLists];

      temp_array.splice(array_position, 1);
      setModuleLists(temp_array);

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const deleteCourse = async (
  setcourseLsists,
  temp_array,
  array_position
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_id: temp_array[array_position].id,
  };

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/deleteCourse",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      temp_array.splice(array_position, 1);
      setcourseLsists(temp_array);

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const addModuleIntoCourse = async (
  setModuleLists,
  temp_array,
  module_id,
  course_id
) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    course_id: course_id,
    module_id: module_id,
  };

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/linkModuleIntoCourse",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (response.data.issuccess) {
      //temp_array.splice(array_position, 1);
      setModuleLists(temp_array);

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const upDateCourseHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

 // console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("upload_file", post_data.courseImageFile);

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateCourse",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};



export const copyMeetingHandler = async (post_data, setliveLessionLists) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

  console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
 

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createDuplicateMeeting",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      setliveLessionLists(response.data.meeting_lists);

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};


export const updateMeetingHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

 // console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));

  

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateMeeting",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const createCourse = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: post_data,
  };

  

  
  let response = "";

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("upload_file", post_data.courseImageFile);

  


  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createCourse",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};




export const createMeetingHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: post_data,
  };

  let response = "";

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createMeeting",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};



export const upDateModuleHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  
  
  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("moduleImageFile", post_data.moduleImageFile);
  fromdata.append("previewUrlFile", post_data.previewUrlFile);

  let response = "";

  
  //resetData();

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateModel",
      fromdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};




export const createCerTificate = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  
  
  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  
  fromdata.append("certTopLeftImage", post_data.certTopLeftImage);
  fromdata.append("certTopRightImage", post_data.certTopRightImage);
  fromdata.append("uploadSignatureImage", post_data.uploadSignatureImage);


  let response = "";

  
  //resetData();

  

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createCertificate",
      fromdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }

  
};




export const updateCertificate = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  
  
  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("certTopLeftImage", post_data.certTopLeftImage);
  fromdata.append("certTopRightImage", post_data.certTopRightImage);
  fromdata.append("uploadSignatureImage", post_data.uploadSignatureImage);

  
  
  //fromdata.append("previewUrlFile", post_data.previewUrlFile);

  

  let response = "";

  
  //resetData();

  

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateCertificate",
      fromdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }

  
};


export const createModule = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: post_data,
  };

  let response = "";

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("moduleImageFile", post_data.moduleImageFile);
  fromdata.append("previewUrlFile", post_data.previewUrlFile);

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createModel",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};





export const updateLessonHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

  //console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("moduleImageFile", post_data.lessonImageFile);
  fromdata.append("previewUrlFile", post_data.lessonContentFile);

  let response = "";

  
  //resetData();

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateLesson",
      fromdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};



export const updateModelHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

  //console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("moduleImageFile", post_data.moduleImageFile);
  fromdata.append("previewUrlFile", post_data.previewUrlFile);

  let response = "";

  
  //resetData();

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateLesson",
      fromdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};

export const createLessonHandler = async (post_data, resetData) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: post_data,
  };

  let response = "";

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("moduleImageFile", post_data.lessonImageFile);
  fromdata.append("previewUrlFile", post_data.lessonContentFile);

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createLesson",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }
};




export const loadfeaTureLists = async (setprivilegesLists, editRollFromData) => {
  
  const getCourseJsonLists = { token: localStorage.getItem("session_token"),"rollId":editRollFromData?editRollFromData.id:"0" };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getFeatureLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    setprivilegesLists(response.data.privilegesData);
  } catch (err) {
  } finally {
  }
};


export const loadAccessLabel = async (setprivilegesLists) => {
  
  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getAccessLabel",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    setprivilegesLists(response.data.accesslabeldata);
  } catch (err) {
  } finally {
  }
};



export const createRoll = async (post_data,reset) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

  console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
 

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createRoll",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      setliveLessionLists(response.data.meeting_lists);

      reset();
      return true;
    }
  } catch (err) {
    reset();
  } finally {
    reset();
    return false;
  }

  
};



export const updateRoll = async (post_data,reset) => {
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };

  console.log(post_data.courseImageFile);

  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
 

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateRoll",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      setliveLessionLists(response.data.meeting_lists);

      reset();
      return true;
    }
  } catch (err) {
    reset();
  } finally {
    reset();
    return false;
  }

  
};






export const fetchRolls = async (setprivilegesLists) =>{


  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getRolls",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );


     
   // console.log(response.data.rollLists);
    setprivilegesLists(response.data.rollLists);
    return true;
  } catch (err) {
    return false;
  } finally {
    return false;
  }

  
}




export const fetchNurchingRegisteredStudent = async (setfetchedNursingData,editUserFromData) =>{


  const getCourseJsonLists = { token: localStorage.getItem("session_token"),"enrollmentId":editUserFromData.enrollmentId };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getNursingRegisterDetails",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );


     
   // console.log(response.data.rollLists);
   setfetchedNursingData(response.data.nursingData);
    return true;
  } catch (err) {
    return false;
  } finally {
    return false;
  }

  
}





export const upDateuserHandler = async (userCreationFrom, reset) =>{

  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("upload_profile_image", post_data.courseImageFile);

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateUser",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.issuccess) {
      resetData();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }

}

export const createUserHandler = async (post_data, reset,toast) =>{
  
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("upload_profile_image", post_data.userProfilePictureFile);

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/createUser",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    //console.log(response.data.json_data);

    if (response.data.issuccess) {
      resetData();

      return true;
    }else{
      toast({
        position: "bottom-left",
        title: "Notification",
        description: response.data.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
  } catch (err) {
  } finally {
    return false;
  }

}



export const updateNurSingStatus = async (post_data, reset,qualificationLists) =>{
  
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("qualificationLists", JSON.stringify(qualificationLists));
  
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("upload_profile_image", post_data.userProfilePictureFile);

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateUserNurSimgRegister",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(response.data.json_data);

    if (response.data.issuccess) {
      reset();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }

}
export const updateUserHandler = async (post_data, reset,toast) =>{
  
  const getCourseJsonLists = {
    token: localStorage.getItem("session_token"),
    post_data: JSON.stringify(post_data),
  };


  const fromdata = new FormData();
  fromdata.append("post_data", JSON.stringify(post_data));
  fromdata.append("token", localStorage.getItem("session_token"));
  fromdata.append("upload_profile_image", post_data.userProfilePictureFile);

  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/updateUser",
      fromdata,
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(response.data.json_data);

    if (response.data.issuccess) {
      reset();

      return true;
    }
  } catch (err) {
  } finally {
    return false;
  }

}

export const fetchDefaultRollss = async (setuserDefaultRolls,userId) =>{

  
  const getCourseJsonLists = { token: localStorage.getItem("session_token"),userId:userId };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/fetchRollss",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );


     
   // console.log(response.data.rollLists);
   setuserDefaultRolls(response.data.rollLists);
  } catch (err) {
  } finally {
  }

}


export const fetchUserLists = async (setprivilegesLists) =>{


  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getUserLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );


     
   // console.log(response.data.rollLists);
    setprivilegesLists(response.data.rollLists);
  } catch (err) {
  } finally {
  }

  
}


export const fetchStudentLists = async (setprivilegesLists) =>{


  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getStudentLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );


     
  // console.log(response.data.student_lists);
    setprivilegesLists(response.data.student_lists);
  } catch (err) {
  } finally {
  }

  
}



export const fetchManagerollRolls = async (setprivilegesLists) =>{


  const getCourseJsonLists = { token: localStorage.getItem("session_token") };
  let response = "";

  try {
    response = await axios.post(
      constdata.mainUrl + "LearnhatapiCourse/getRollsLists",
      getCourseJsonLists,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );


     
   // console.log(response.data.rollLists);
    setprivilegesLists(response.data.rollLists);
  } catch (err) {
  } finally {
  }

  
}






