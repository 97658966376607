/* eslint-disable */
import {  useEffect,  useState } from "react";
import { Box, Button, Flex,  Select } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  useToast,
 } from "@chakra-ui/react";
import {
  loadUserCourseLists,
  loadCourseTypeHandler,
  updateLessonHandler,
  createLessonHandler,

} from "../../Helper/Courselists.helper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ManageLessionBreadcum } from "./ManageLessionBreadcum";


export const CreateLession = (props) => {
  //const {element} =  useLocation();
  //const { state } = useLocation();
 // console.log(JSON.parse(state))
 // let referData = state ? JSON.parse(state) : null;

  const toast = useToast();
  const lessonData = useSelector((state) => state.lesson_data);
  
  //lessonData = create_meeting_form?create_meeting_form:null;

  const addStudentOrremove = useDispatch();

  

  const  [hideUploadInputs,sethideUploadInputs] = useState(false);

  const [liveclass, setLiveClass] = useState({
    lessionId: lessonData ? lessonData.id : "",
    lessonName: lessonData ? lessonData.name : "",
    lessonDescription: lessonData ? lessonData.lessonDescription : "",
    lessonImage: lessonData ? lessonData.lessonImage : "",
    lessonContent: lessonData ? lessonData.lessonContent : "",
    lessonType: lessonData ? lessonData.lessonMime : "",
    lessonImageFile:  "",
    lessonContentFile:  "",
  });

  
  const [courseType, setCourseType] = useState([]);
    


  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();



  const onSubmit = (values) => {
    return new Promise((resolve) => {
     
      
     
     // liveclass.student_lists = studentlists;

     

      if (lessonData && lessonData.id) {

       // console.log(" Course Update .... ");
        const returnval = updateLessonHandler(liveclass, reset);
        // reset();

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Lesson  successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        const returnval = createLessonHandler(liveclass, reset);

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Lesson successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  };

  const [moduleAndCourseLists, setmoduleAndCourseLists] = useState({
    meetingModuleLists: [],
    meetingLessionLists: [],
    courselists: [],
    studentlists: [],
  });

  

  

  


  useEffect(() => {
   
    const returnvalue = loadUserCourseLists(
      setmoduleAndCourseLists,
      lessonData
    );


    loadCourseTypeHandler(setCourseType);

    return () => {
      if (window.location.pathname !== "/createlession") {
       
        addStudentOrremove({
          type: "save_lesson",
          lesson_data: null,
        });

        
      }

      
    };
  }, []);


  

  useEffect(() => {
    
    addStudentOrremove({
      type: "create_meeting_data",
      create_meeting_form: liveclass,
    });

    



  }, [liveclass]);


  useEffect(()=>{

   // console.log("ok 4 :"+lessonData.lessonMime)

    if(lessonData!=null){

     
      
      if(Number(lessonData.lessonMime)===4){
        sethideUploadInputs(true);
       // console.log("ok 3 ")

      }

      if(Number(lessonData.lessonMime)===3){
        sethideUploadInputs(true);
       // console.log("ok 4 ")

      }

                 

    }

  },[]);


  const resetState = () =>{

    setLiveClass((oldstate) => {
      return {
        lessionId: "",
        lessonName:  "",
        lessonDescription:  "",
        lessonImage:  "",
        lessonContent:  "",
        lessonType:  "",
        lessonImageFile:  "",
        lessonContentFile:  "",
      };
    });


    

  }

  return (
    <>

     
      <ManageLessionBreadcum seleted_item="createcourse" resetState={resetState} />

      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={"hidden"}
            name="lessionId"
            value={liveclass ? liveclass.lessionId : "0"}
          />

          <Flex>
            
            
            
            <FormControl isInvalid={errors.lession_name} m={"10px"}>
              <FormLabel htmlFor="lession_name">Lesson Name</FormLabel>
              <Input
                id="lession_name"
                name="lession_name"
                placeholder="Lesson Name"
                {...register("lession_name", {
                  required: "Please enter lesson name",
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      lessonName: event.target.value,
                    };
                  });
                }}
                value={liveclass.lessonName}
              />
              <FormErrorMessage>
                {errors.lession_name && errors.lession_name.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex>
            <FormControl isInvalid={errors.lession_description} m={"10px"}>
              <FormLabel htmlFor="lession_description">
              Lesson Description
              </FormLabel>
              <Textarea
                id="lession_description"
                name="lession_description"
                placeholder="Lesson Description"
                {...register("lession_description", {
                  required: "Please enter lesson description",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      lessonDescription: event.target.value,
                    };
                  });
                }}
                value={liveclass.lessonDescription}
              />
              <FormErrorMessage>
                {errors.lession_description && errors.lession_description.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl isInvalid={errors.course_select_tyep} m={"10px"}>
              <FormLabel htmlFor="course_select_tyep">
                Lesson Type  
              </FormLabel>
              <Select
                key="seletc3"
                name="course_select_tyep"
                placeholder="Select lesson Type"
                {...register("course_select_tyep", {
                  required: "Please select lesson type",
                })}
                {...setValue(
                  "course_select_tyep",
                  liveclass.lessonType
                )}
                onChange={(event) => {
                 // console.log("Number: "+Number(event.target.value));

                  sethideUploadInputs(Number(event.target.value)===2?false:true);
                  


                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      lessonType: event.target.value,
                    };
                  });

                }}
                value={liveclass.lessonType}
              >
                {courseType.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.courseType}
                    </option>
                  );
                })}
              </Select>

              <FormErrorMessage>
                {errors.course_select_tyep &&
                  errors.course_select_tyep.message}
              </FormErrorMessage>
            </FormControl>

          <Flex>


      
          <FormControl isInvalid={errors.upload_module_picture} m={"10px"} style={{ display:!hideUploadInputs?'none':'' }}>
              <FormLabel htmlFor="upload_module_picture">
              Lesson Picture
              </FormLabel>

              <input
                type="file"
                name="upload_module_picture"
                onChange={(event) => {


                  setLiveClass((oldState) => {
                    return {
                      ...oldState,
                      lessonImageFile:event.target.files[0],
                    };
                  });
                  
                }}
              />
            </FormControl>


            <FormControl isInvalid={errors.upload_module_picture} m={"10px"} style={{ display:!hideUploadInputs?'none':'' }}>
              <FormLabel htmlFor="upload_module_picture">
              Lesson Media Content
              </FormLabel>

              <input
                type="file"
                name="upload_module_picture"
                onChange={(event) => {


                  setLiveClass((oldState) => {
                    return {
                      ...oldState,
                      lessonContentFile:event.target.files[0],
                    };
                  });
                  
                }}
              />
            </FormControl>




          </Flex>

          
          <Flex flexDirection={"row"}>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>

      
    </>
  );
};
