/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Text,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spacer,
  Center,
  Button,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";

import { ModuleBreadcum } from "./Modulebreadcum";
import {
  loadModules,
  inactiVeCourse,
  deleteModuleFromCourse,
  findSeletedItemPosition,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlert } from "../../component/Customalert";
import { useDispatch, useSelector } from "react-redux";

export const Listsmodules = (props) => {
  const course_data = props.courseData; // useSelector((state) => state.course_data);
  const moduledispacher = useDispatch();

  //console.log(courseData);
  const course_id = course_data ? course_data.id : "0";

  const perPageConstant = 50;
  const [moduleLists, setModuleLists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);

  const [indexCourse, setindexCourse] = useState(-1);

  const [showdialog, setShowdialog] = useState({
    show : false,
    title: '',
    description: '',
    store_data:null,
    array_position:null,
  });

 

  const toast = useToast();

  const navigate = useNavigate();

  

  const cancelAlertHandler = () =>{

    setShowdialog((oldState)=>{
         return {
           ...oldState,
           show:false,
         }

    })

  }

  const deleteAlertHandler = () =>{
   
   
    setShowdialog((oldState)=>{
      return {
        ...oldState,
        show:false,
      }
      });

   
    

    
      const array_position = showdialog.array_position;
      const  course_id =   course_data.id;
      const  module_id =  showdialog.store_data.id;

   const returnValue =  deleteModuleFromCourse(moduleLists,setModuleLists,course_id,module_id,array_position);

      if(returnValue){
        toast({
          position: "bottom-left",
          title: "Notification",
          description: "Module deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

     

    
  }

 

  function searchBytext(item) {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) ||
      searchPattern.test(item.courseAuthor) ||
      searchPattern.test(item.sectorName)
    );
  }

 

  useEffect(() => {
    // await
    loadModules(setModuleLists, course_data ? course_data.id : "0");
    // setcourseLsistsPagination(courseLsists.slice(pageStart, perPage));
  }, []);

 if( Number.parseInt(moduleLists.length) === 0)
 {

           return (
               ''

           );
  }

  return (
    <>

      
      <Flex flexDirection={"columns"}>
        <Text p="2" pl="15" mt="4" >
            Module lists for this course
        </Text>
        <Spacer />

        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setcourseLsists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>

        
        <CustomAlert 
          show={showdialog.show}
          title={showdialog.title}
          description={showdialog.description}
          cancelAlert={cancelAlertHandler}
          deleteAlert={deleteAlertHandler}

        /> 
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Lesson Count</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(moduleLists.length) >= 1 &&
              moduleLists.filter(searchBytext).map((element) => (
                <Tr key={element.id}>
                  <Td>
                    <Text>{element.name}</Text>
                  </Td>
                  <Td>
                    <Text>{element.moduleDescription}</Text>
                  </Td>
                  <Td>
                    <Text>{element.lesson_count}</Text>
                  </Td>
                  <Td>
                    <HStack mt={"10px"}>
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Edit Course"
                        icon={<EditIcon />}
                        onClick={() => {
                          if (true) {
                            const array_position = findSeletedItemPosition(
                              moduleLists,
                              element
                            );

                           

                            moduleLists[array_position].course_id = course_id;
                            const data_object = moduleLists[array_position];

                            moduledispacher({
                              type: "store_module_data",
                              module_data: data_object,
                            });

                            navigate("/createmodule", {
                              state: JSON.stringify(data_object),
                            });
                          }
                        }}
                        // mx={2}
                      />

                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Delete Course"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          if (true) {
                            const array_position = findSeletedItemPosition(
                              moduleLists,
                              element
                            );

                            

                            setShowdialog((oldState)=>{
                              return {
                                ...oldState,
                                show:true,
                                store_data:element,
                                array_position:array_position,
                                title:'Delete Item Notification',
                                description:'Do you want to delete this module from this course?',

                              }
                     
                         })
                            
                              

                          }
                        }}
                        // mx={2}
                      />
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Inactive Course"
                        onClick={() => {
                          let temp_array = [...moduleLists];
                          const array_position = findSeletedItemPosition(
                            temp_array,
                            element
                          );
                          const returnResponse = inactiVeCourse(
                            setModuleLists,
                            temp_array,
                            array_position
                          );
                          if (returnResponse) {
                            toast({
                              position: "bottom-left",
                              title: "Notification",
                              description:
                                Number.parseInt(element.isActive) === 1
                                  ? "Course activated successfully"
                                  : " Course Inactivated successfully.",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            });
                          }
                        }}
                        icon={
                          Number.parseInt(element.isActive) === 1 ? (
                            <ViewOffIcon />
                          ) : (
                            <ViewIcon />
                          )
                        }
                        // mx={2}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {(moduleLists.filter(searchBytext).length > perPageConstant) & false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={moduleLists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};

export default Listsmodules;
