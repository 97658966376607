/* eslint-disable */
import { useState, React, useEffect } from "react";
import { ManageUserBreadCum } from "./Manageuserbreadcum";

import  "./Editcertificate.modal.css"

import {
  Box,
  Button,
  Flex,
  Select,
  Image,
  Text,
  Checkbox,
  HStack,
  VStack,
  Img,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import {
  updateUserHandler,
  createUserHandler,
  fetchCertificateTemPlate,
  fetchCertificatePrefixMenu,
  fetchGetCertificateCourse
} from "../../Helper/Courselists.helper";

import { useSelector, useDispatch } from "react-redux";
import {
  updateCertificate,
  createCerTificate,
  fetchAssignedCourses,
} from "../../Helper/Courselists.helper";

import { Select as MultiSelect } from "chakra-react-select";
import { UserListsBreadcome } from "./UserListsBreadcome";
import { ManageCertificateBreadCome } from "./ManageCertificateBreadCome";

export const Editcertificate = () => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const certificateData = useSelector((state) => state.certificateData);

  console.log("Show certificate Data: ");
  console.log(certificateData);




  const [userCertificateFrom, setuserCertificateFrom] = useState({
    certificateId: certificateData ? certificateData.id : "0",
    courseSeleted: certificateData ? certificateData.courseSeleted : "",
    certificateName: certificateData ? certificateData.templateName : "",
    certificateText: certificateData ? certificateData.certText : "",
    certificateDesc: certificateData ? certificateData.templateDesc : "",
    secriteryName: certificateData ? certificateData.certSignName : "",
    uploadSignatureImage: "",
    uploadSignatureImagePrev: certificateData ? certificateData.certSignImage : "",
    certTopLeftImage:"",
    certTopLeftImagePrev:certificateData ? certificateData.certTopLeftImage : "",
    certTopRightImage:"",
    certTopRightImagePrev:certificateData ? certificateData.certTopRightImage : "",
    userseletedTemplate:certificateData ? certificateData.certBgId : "",
    designationOfSignatory: certificateData ?certificateData.certSignDesg:"",
    certificatePrefix:certificateData ? certificateData.mnemonicId : "",
    uploadSignatureImage:"",
    certTopLeftImage:"",
    certTopRightImage:"",
    userseletedTemplate:certificateData? certificateData.certBgId:"",
  });


  

  const handleChangeforModules = (newValue, actionMeta) => {
    setuserCertificateFrom((oldstate) => {
      return {
        ...oldstate,
        courseSeleted: newValue,
      };
    });
  };


  const toast = useToast();

  const [courseLists, setcourseLists] = useState([]);

  const [certificatePrefix, setcertificatePrefix] = useState([]);

  function onSubmit(values) {
    return new Promise((resolve) => {

      if (userCertificateFrom && userCertificateFrom.certificateId) {

        

        const returnval = updateCertificate(userCertificateFrom, reset);
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Certificate  successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });

          reset();
        }
      } else {
        const returnval = createCerTificate(userCertificateFrom, reset);

       
        reset();
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Certificate successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  }

  const resetCertificateData  = () => {
    setuserCertificateFrom({
      certificateId: "0",
      courseSeleted:  "",
      certificateName:  "",
      certificateText:  "",
      certificateDesc:  "",
      secriteryName:  "",
      uploadSignatureImage: "",
      certTopLeftImage:"",
      certTopRightImage:"",
      userseletedTemplate:"",
      designationOfSignatory:"",
      certificatePrefix:"",
      uploadSignatureImage:"",
      certTopLeftImage:"",
      certTopRightImage:"",
      userseletedTemplate:"",
    })
  };

  const [cerImage, setcerImage] = useState("");
  const [certTopRimage, setcertTopRightImage] = useState("");
  const [certTopLemage, setcertTopLeftImage] = useState("");

  const [signatureImage, setsignatureImage] = useState("");
  
  const [certificateTemplates, setcertificateTemplates] = useState([]);
  const [seletedCertificate, setseletedCertificate] = useState(null);
  const [reloadMultiselect,setreloadMultiselect] = useState(false);
  const [topimgPath,settopimgPath] = useState("");
  const [topRimgPath,settopRimgPath] = useState("");
  const [bottomSignature,setbottomSignature] = useState("");
  


  useEffect(()=>{
    setcertTopLeftImage(userCertificateFrom.certTopLeftImagePrev);
    setcertTopRightImage(userCertificateFrom.certTopRightImagePrev);
    setsignatureImage(userCertificateFrom.uploadSignatureImagePrev);
    

  },[])


  useEffect(()=>{
    const certTopLemageArray = certTopLemage.split("/");

    settopimgPath(certTopLemageArray[certTopLemageArray.length - 1]);

  },[certTopLemage]);

  useEffect(()=>{
    const certTopLemageArray =  certTopRimage.split("/");

    settopRimgPath(certTopLemageArray[certTopLemageArray.length - 1]);

  },[signatureImage]);


  useEffect(()=>{
    const certTopLemageArray = signatureImage.split("/");

    setbottomSignature(certTopLemageArray[certTopLemageArray.length - 1]);

  },[signatureImage]);

  function searchByTemplateId(item) {
    var searchPattern = new RegExp("^" + templateId, "i", "g");
    return (
      searchPattern.test(item.id)
      
    );
  }

 
  
  useEffect(()=>{

   let seletedTemplateId = userCertificateFrom.userseletedTemplate;
   //setTemplateId(seletedTemplateId);
   

    certificateTemplates.map((content, index) => {
    if(content.id===seletedTemplateId){
     // console.log("Setect Bg Template");
     // console.log(content.certBgURL);
      setcerImage(content.certBgURL);
    }


   });
   
   //let seletedTemplate =  certificateTemplates.filter(searchByTemplateId)
    
   // setcerImage(userCertificateFrom.certTopLeftImagePrev);
    

  },[certificateTemplates])
 
  
  

  useEffect(()=>{

    fetchCertificateTemPlate(setcertificateTemplates);
    fetchCertificatePrefixMenu(setcertificatePrefix);
    fetchGetCertificateCourse(setcourseLists);
    if (userCertificateFrom && userCertificateFrom.certificateId) {
      fetchAssignedCourses(setseletedCertificate,userCertificateFrom.certificateId);

    }

  },[]);


  useEffect(()=>{

    
     
    setuserCertificateFrom((oldstate) => {
      return {
        ...oldstate,
        courseSeleted: seletedCertificate,
      };
    });

  },[seletedCertificate])


  useEffect(()=>{

    if(seletedCertificate!==null){

      
    setreloadMultiselect(!reloadMultiselect);

    }


  },[seletedCertificate]);

  return (
    <>
      <ManageCertificateBreadCome   resetCertificateData={resetCertificateData} />

      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction={"column"}>
            <Flex direction={"rows"}>
              <FormControl isInvalid={errors.certificate_name} m={"10px"}>
                <FormLabel htmlFor="certificate_name">
                   Name
                </FormLabel>
                <Input
                  id="certificate_name"
                  name="certificate_name"
                  placeholder="Certificate Name"
                  {...register("certificateName", {
                    required: "Please certificate name",
                  })}
                  onChange={(event) => {
                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        certificateName: event.target.value,
                      };
                    });
                  }}
                  value={userCertificateFrom.certificateName}
                />
                <FormErrorMessage>
                  {errors.certificate_name && errors.certificate_name.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex>

            <FormControl isInvalid={errors.certificate_text} m={"10px"}>
                <FormLabel htmlFor="certificate_text">
                   Desc
                </FormLabel>
                <Textarea
                  id="certificate_text"
                  name="certificate_text"
                  placeholder="Certificate Text"
                  {...register("certificateDesc", {})}
                  onChange={(event) => {
                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        certificateDesc: event.target.value,
                      };
                    });
                  }}
                  value={userCertificateFrom.certificateDesc}
                />
                <FormErrorMessage>
                  {errors.certificate_text && errors.certificate_text.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.certificate_text} m={"10px"}>
                <FormLabel htmlFor="certificate_text">
                  Certificate Text
                </FormLabel>
                <Textarea
                  id="certificate_text"
                  name="certificate_text"
                  placeholder="Certificate Text"
                  {...register("certificateText", {})}
                  onChange={(event) => {
                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        certificateText: event.target.value,
                      };
                    });
                  }}
                  value={userCertificateFrom.certificateText}
                />
                <FormErrorMessage>
                  {errors.certificate_text && errors.certificate_text.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.secritery_name} m={"10px"}>
                <FormLabel htmlFor="secritery_name">
                  Signatory Name
                </FormLabel>
                <Input
                  id="secritery_name"
                  name="secritery_name"
                  placeholder="Name of Secritery"
                  {...register("secriteryName", {
                    required: "Please enter name",
                  })}
                  onChange={(event) => {
                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        secriteryName: event.target.value,
                      };
                    });
                  }}
                  value={userCertificateFrom.secriteryName}
                />

                <FormErrorMessage>
                  {errors.secritery_name && errors.secritery_name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.user_description} m={"10px"}>
                <FormLabel htmlFor="designation_of_signatory">
                Signatory Designation
                </FormLabel>
                <Input
                  id="designation_of_signatory"
                  name="designation_of_signatory"
                  placeholder="Name"
                  {...register("designationOfSignatory", {
                    required: "Please enter designation of signatory",
                  })}
                  onChange={(event) => {
                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        designationOfSignatory: event.target.value,
                      };
                    });
                  }}
                  value={userCertificateFrom.designationOfSignatory}
                />

                <FormErrorMessage>
                  {errors.designationOfSignatory &&
                    errors.designationOfSignatory.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex>
              <FormControl isInvalid={errors.certificate_prefix} m={"10px"}>
                <FormLabel htmlFor="certificate_prefix">
                  Certificate Prefix
                </FormLabel>
                <Select
                  key="seletc3"
                  name="course_select_tyep"
                  {...register("certificatePrefix", {
                    required: userCertificateFrom && userCertificateFrom.certificateId?false:"Please enter certificate prefix",
                  })}
                  onChange={(event) => {
                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        certificatePrefix: event.target.value,
                      };
                    });
                  }}
                  value={userCertificateFrom.certificatePrefix}
                >
                     <option key={'key_0'} value={''}>
                        {'Certificate prefix'}
                      </option>
                  {certificatePrefix.map((ele) => {
                    return (
                      <option key={ele.id} value={ele.id}>
                        {ele.mnemonic}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {errors.certificatePrefix && errors.certificatePrefix.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.select_course_type} m={"10px"}>
                <FormLabel htmlFor="select_course_type">
                  Assigned Courses (Multipal course can be seleted)
                </FormLabel>

                {!reloadMultiselect || reloadMultiselect &&  

                <MultiSelect
                  isMulti
                  name="colors"
                  options={courseLists}
                  closeMenuOnSelect={false}
                  onChange={handleChangeforModules}
                  defaultValue={seletedCertificate}
                ></MultiSelect>

                }

                <FormErrorMessage>
                  {errors.select_course_type &&
                    errors.select_course_type.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex flexDirection={"row"}>


              <FormControl isInvalid={errors.certTopLeftImage} m={"10px"}>
                <FormLabel htmlFor="certTopLeftImage">
                Top Left Image
                </FormLabel>

                <input
                  type="file"
                  name="certTopLeftImage"
                  onChange={(event) => {

                    setcertTopLeftImage(URL.createObjectURL(event.target.files[0]));

                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        certTopLeftImage: event.target.files[0],
                      };
                    });
                  }}
                />
                <span>
                { topimgPath }
                </span>
               

                </FormControl>


                <FormControl isInvalid={errors.certTopRightImage} m={"10px"}>
                <FormLabel htmlFor="certTopRightImage">
                Top Right Image
                </FormLabel>

                <input
                  type="file"
                  name="certTopRightImage"
                  onChange={(event) => {
                   
                    setcertTopRightImage(URL.createObjectURL(event.target.files[0]));

                    setuserCertificateFrom((oldstate) => {
                      return {
                        ...oldstate,
                        certTopRightImage: event.target.files[0],
                      };
                    });
                  }}
                />


<span>
                { topRimgPath }
                </span>

                </FormControl>



            </Flex>    





            <Flex flexDirection={"row"}>
              <FormControl isInvalid={errors.upload_signature_image} m={"10px"}>
                <FormLabel htmlFor="upload_signature_image">
                  Signature Image
                </FormLabel>

                <input
                  type="file"
                  name="upload_signature_image"
                  
                  onChange={(event) => {


                    //console.log("Upload File Select");
                   // console.log(URL.createObjectURL(event.target.files[0]));
                    setsignatureImage(URL.createObjectURL(event.target.files[0]));

                    setuserCertificateFrom((oldstate) => {

                      return {
                        ...oldstate,
                        uploadSignatureImage: event.target.files[0],
                      };
                    });
                  }}
                />
                {bottomSignature}

                <VStack>

                  <Text>
                  Certificate  Template 
                  </Text>

                <HStack mt={10}>
                  {certificateTemplates.map((content, index) => {
                    return (
                      <>
                        <Image
                          src={content.certBgURL}
                          w={120}
                          h={120}
                          onClick={() => {

                            
                            toast({
                              position: "bottom-left",
                              title: "Notification",
                              description: "Template selected",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            });

                            setcerImage(content.certBgURL);

                            setuserCertificateFrom((oldstate) => {
                              return {
                                ...oldstate,
                                userseletedTemplate: content.id,
                              };
                            });
                          }}
                        />
                      </>
                    );
                  })}
                </HStack>

                

               

                </VStack>
              </FormControl>

              
            </Flex>

            <Flex>
                 
            <FormControl m={"10px"}>
                <FormLabel>Certificate Preview </FormLabel>
                
                 <div>
                 <Image  w={"120px"} src={certTopLemage} className="classcertificateleft" />
                 <Image  w={"120px"} src={certTopRimage} className="classcertificateright" />
                 <Image  w={"120px"} src={signatureImage}  className="signatureclass"  />
                 <Image  w={"60%"} src={cerImage} />

                 <Text  className="certificateContent">
                 { userCertificateFrom.certificateText}
                 </Text>


                 <Text className="certificateContentName">
                 {userCertificateFrom.secriteryName}
                 </Text>


                

                 <Text className="certificateContentDeg">
                 {userCertificateFrom.designationOfSignatory}
                 </Text>
                 
                 </div>
              
              </FormControl>

            </Flex>

            <Flex flexDirection={"row"}>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>

              <Button mt={4} colorScheme="teal" type="button" ml={10}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
};
