/* eslint-disable */
import { useLoginContext } from "../../context/Logincontextprovider";
import { ReactNode, useEffect, useState } from "react";
import { Box, Button, Flex, Image, Select, toast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ModuleBreadcum } from "./Modulebreadcum";
import { useLocation } from "react-router-dom";
import {
  upDateModuleHandler,
  createModule,
} from "../../Helper/Courselists.helper";
import { useSelector, useDispatch } from "react-redux";
import { CourseBreadcum } from "../Createcourse/CourseBreadcum";
import { LessionListsForModule } from "./LessionListsForModule";

export const CreateModule = () => {
  const module_data = useSelector((state) => state.module_data);
  const course_data = useSelector((state) => state.course_data);
  const reducUseDispatch = useDispatch();

  const toast = useToast();

  

  
  const [moduleFormData, setModuleFormData] = useState({
    moduleId: module_data ? module_data.id : "0",
    courseId: course_data ? course_data.id : "0",
    name: module_data ? module_data.name : "",
    moduleDescription: module_data ? module_data.moduleDescription : "",
    moduleImage: module_data ? module_data.moduleImage : "",
    previewUrl: module_data ? module_data.previewUrl : "",
    moduleImageFile: "",
    previewUrlFile: "",
    moduleLesson: [],
  });

  const isItemChecked = (lessionId)=>{

    const returnValue = moduleFormData.moduleLesson.find(
      (less) => less === lessionId
    );

    if (!returnValue) {
    }else{
      return true;
    }
    return false;

  }


  const updateModulelessionLists  = (lessonLists) =>{

    setModuleFormData((oldState) => {
      return {
        ...oldState,
        moduleLesson: lessonLists,
      };
    });

   

  }
  const updateModuleLesson = (lessionId) => {
    const returnValue = moduleFormData.moduleLesson.find(
      (less) => less === lessionId
    );
    //console.log(" returnValue : "+returnValue);

    if (!returnValue) {
      //console.log("It is ok 1");

      const moduleLesson = [...moduleFormData.moduleLesson, lessionId];

      setModuleFormData((oldState) => {
        return {
          ...oldState,
          moduleLesson: moduleLesson,
        };
      });

     // console.log(moduleLesson);
    } else {
      const returnValue = moduleFormData.moduleLesson.indexOf(lessionId);

      // console.log(returnValue);

      const moduleLesson = [...moduleFormData.moduleLesson];
      moduleLesson.splice(returnValue, 1);

      setModuleFormData((oldState) => {
        return {
          ...oldState,
          moduleLesson: moduleLesson,
        };
      });

    //  console.log(returnValue);

      // console.log("It is ok 2");
    }
  };

  


 

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(values) {
    return new Promise((resolve) => {


      


     // if(moduleFormData.moduleLesson.length===0){

      

      
     
      if (module_data) {
        const returnval = upDateModuleHandler(moduleFormData, reset);
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Module successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        const returnval = createModule(moduleFormData, reset);

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Course successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  }





  return (
    <>
      <CourseBreadcum courseData={course_data} />

      

      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={"hidden"}
            name="module_id"
            value={module_data ? module_data.id : "0"}
          />
          <Flex>
            <FormControl isInvalid={errors.module_name} m={"10px"}>
              <FormLabel htmlFor="module_name">Module Name</FormLabel>
              <Input
                id="module_name"
                name="module_name"
                placeholder="Module Name"
                {...register("module_name", {
                  required: "Please enter module name",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                onChange={(event) => {
                  setModuleFormData((oldState) => {
                    return {
                      ...oldState,
                      name: event.target.value,
                    };
                  });
                }}
                value={moduleFormData.name}
              />
              <FormErrorMessage>
                {errors.course_name && errors.course_name.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex>
            <FormControl isInvalid={errors.module_description} m={"10px"}>
              <FormLabel htmlFor="module_description">
                Module Description
              </FormLabel>
              <Textarea
                id="module_description"
                name="module_description"
                placeholder="Module Description"
                {...register("module_description", {
                  required: "Please enter course description",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                onChange={(event) => {
                  setModuleFormData((oldState) => {
                    return {
                      ...oldState,
                      moduleDescription: event.target.value,
                    };
                  });
                }}
                value={moduleFormData.moduleDescription}
              />
              <FormErrorMessage>
                {errors.course_description && errors.course_description.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"}>
            {moduleFormData.moduleImage ? (
              <FormControl m={"10px"}>
                <FormLabel>Uploaded module Picture</FormLabel>

                {moduleFormData.moduleImage.length >= 1 ? (
                  <Image src={moduleFormData.moduleImage} h={"120px"} />
                ) : (
                  <Text></Text>
                )}
              </FormControl>
            ) : (
              <Text></Text>
            )}

            <FormControl isInvalid={errors.upload_module_picture} m={"10px"}>
              <FormLabel htmlFor="upload_module_picture">
                Module Picture
              </FormLabel>

              <input
                type="file"
                name="upload_module_picture"
                onChange={(event) => {
                  //  console.log(event.target.files[0]);
                  setModuleFormData((oldState) => {
                    return {
                      ...oldState,
                      moduleImageFile: event.target.files[0],
                    };
                  });
                }}
              />
            </FormControl>

            {moduleFormData.previewUrl ? (
              <FormControl m={"10px"}>
                <FormLabel>Uploaded module Preview Url</FormLabel>

                {moduleFormData.previewUrl.length >= 1 ? (
                  <Image src={moduleFormData.previewUrl} h={"120px"} />
                ) : (
                  <Text></Text>
                )}
              </FormControl>
            ) : (
              <Text></Text>
            )}

            <FormControl isInvalid={errors.upload_preview_url} m={"10px"}>
              <FormLabel htmlFor="upload_preview_url">
                Module Preview Media
              </FormLabel>

              <input
                type="file"
                name="upload_preview_url"
                onChange={(event) => {
                  //  console.log(event.target.files[0]);

                  setModuleFormData((oldState) => {
                    return {
                      ...oldState,
                      previewUrlFile: event.target.files[0],
                    };
                  });
                }}
              />
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"}>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </form>

        <LessionListsForModule updateModuleLesson={updateModuleLesson} 
        isItemChecked={isItemChecked} 
        moduleId={moduleFormData.moduleId}
        updateModulelessionLists={updateModulelessionLists}
       courseType={course_data.courseType}
       />
      </Box>
    </>
  );
};


