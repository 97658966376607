/* eslint-disable */
import { Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import { useLoginReducer } from "../pages/login/Login.reducer";
import { useLoginContext } from "../context/Logincontextprovider";
import Createcourse from "../pages/Createcourse/Createcourse";
import Listscourses from "../pages/Createcourse/Listscourses";
import { Dashboardnavigation } from "../component/Dashboardnavigation";
import { CreateModule } from "../pages/CreateModule/CreateModule";
import Listsmodules from "../pages/CreateModule/Listsmodules";
import { Listsallmodules } from "../pages/CreateModule/Listsallmodules";
import { Listsliveclassess } from "../pages/Manageliveclasses/Listsliveclassess";
import { Createliveclassess } from "../pages/Manageliveclasses/Createliveclassess";
import { Listsaddstudentforliveclass } from "../pages/Manageliveclasses/Listsaddstudentforliveclass";
import {Managelession} from "../pages/Mnagelession/Managelession";
import { ManagePayment } from "../pages/MnagePayment/ManagePayment";
import { CreateLession } from "../pages/Mnagelession/CreateLession";
import { CreateUser } from "../pages/userManageMent/CreateUser";
import { CreateRoll } from "../pages/userManageMent/CreateRoll.jsx";
import { UserLists } from "../pages/userManageMent/UserLists";
import { Manageroll } from "../pages/userManageMent/Manageroll";
import { Otherusereslists } from "../pages/userManageMent/Otherusereslists";
import { Managecertificatelists } from "../pages/userManageMent/Managecertificatelists";
import { Editcertificate } from "../pages/userManageMent/Editcertificate";
import { StudentFromSubmitLists } from "../pages/StudentFromSubmit/StudentFromSubmitLists";
import { EditRegisterNurSingStudent } from "../pages/StudentFromSubmit/EditRegisterNurSingStudent";


const Loginroutes = () => {
  const { isUserLogin } = useLoginContext();

  return isUserLogin ? (
    <>
      <Dashboardnavigation />

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/createcourse" element={<Createcourse />} />
        <Route path="/courselists" element={<Listscourses />} />
        <Route path="/createmodule" element={<CreateModule />} />
        <Route path="/moduleslists" element={<Listsmodules />} />
        <Route path="/allmoduleslists" element={<Listsallmodules />} />
        <Route path="/listsliveclassess" element={<Listsliveclassess />} />
        <Route path="/createliveclassess" element={<Createliveclassess isEdit={false} />} />
        <Route path="/editliveclassess" element={<Createliveclassess isEdit={true} />} />
        <Route path="/listsaddstudents" element={<Listsaddstudentforliveclass />} />
        <Route path="/managelessionlists" element={<Managelession />} />
        <Route path="/createlession" element={<CreateLession />} />

        <Route path="/managepayments" element={<ManagePayment />} />
        
        <Route path="/createuseres" element={<CreateUser />} />
        <Route path="/listsusers" element={ <UserLists />} />
        <Route path="/createroll" element={<CreateRoll />} />
        <Route path="/manageroll" element={<Manageroll />} />
        <Route path="/otherusermanage" element={<Otherusereslists />} />
        <Route path="/managecertificate" element={<Managecertificatelists />} />
        <Route path="/editcertificate" element={<Editcertificate />} />


        <Route path="/studentFromSubmitLists" element={ <StudentFromSubmitLists />} />
        <Route path="/editUserSubmitedFrom" element={ <EditRegisterNurSingStudent />} />


        
       
      </Routes>
    </>
  ) : (
    <Routes>
      <Route exact path="/" element={<Login />} />
    </Routes>
  );
};

export default Loginroutes;
