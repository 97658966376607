/* eslint-disable */
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Input,
  Tr,
  Th,
  Td,
  Spacer,
  InputGroup,
  Checkbox,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { loadfeaTureLists } from "../../Helper/Courselists.helper";

export const PrivilegesLists = (props) => {
  const [privilegesLists, setprivilegesLists] = useState([]);
  const [searchText, setsearchText] = useState([]);

  const [preVilageLists, setpreVilageLists] = useState([]);

  const searchBytext = (item) => {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return searchPattern.test(item.featureDesciption);
  };

  useEffect(() => {
    loadfeaTureLists(setprivilegesLists, props.editRollFromData);
  }, []);

  useEffect(()=>{
    props.setFeatures(preVilageLists);
  },[preVilageLists]);


  return (
    <>
      <Box ml={2} mr={10}>
        <Flex mt={20}>
          <Flex ml={10}>&nbsp;</Flex>

          <Spacer />
          <InputGroup mr={"20px"} width={"40vw"}>
            <Input
              placeholder="Search"
              onChange={(event) => {
                setsearchText(event.target.value);
              }}
            />
          </InputGroup>
        </Flex>

        <Flex flexDirection={"column"} mt={10}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Privileges For</Th>
                <Th>View</Th>
                <Th>Add</Th>
                <Th>Edit</Th>
                <Th>Delete</Th>
                <Th>Active / Inactive</Th>
              </Tr>
            </Thead>
            <Tbody>
              {privilegesLists &&
                privilegesLists.filter(searchBytext).map((element, index) => {
                  const key_val = "key_" + element.privId;

                  
                 // preVilageLists[index] = previlageListsObject;
                 // setpreVilageLists([...preVilageLists]);

                 const previlageListsObject = {
                  featureName: element.featureName,
                  privId: element.privId,
                  viewPrivVal: element.RviewPriv === "1" ? true : false,
                  createPriv: element.RcreatePriv === "1" ? true : false,
                  editPriv:  element.ReditPriv === "1" ? true : false,
                  deletePriv: element.RdeletePriv === "1" ? true : false,
                  disablePriv: element.RdisablePriv === "1" ? true : false,
                };

                if(!preVilageLists[index]){
                //  console.log("It is Null ");
                  preVilageLists[index] = previlageListsObject;
                  setpreVilageLists(preVilageLists);
                }

               
              //  setpreVilageLists(preVilageLists);



                  return (
                    <Tr key={key_val}>
                      <Td>
                        <Text p={3}>
                          {element.featureDesciption}
                        </Text>
                      </Td>

                      <Td>
                        {element.viewApplicable === "1" && (
                          <Checkbox
                            id="is_view_enable_{element.id}"
                           
                            defaultIsChecked={
                              element.RviewPriv === "1" ? true : false
                            }
                            onChange={(event) => {
                            const  newpreVilageLists = [...preVilageLists];
                            newpreVilageLists[index].viewPrivVal = event.target.checked;
                            setpreVilageLists(newpreVilageLists);
                              props.setFeatures(preVilageLists);
                            }}
                          >
                            View
                          </Checkbox>
                        )}
                      </Td>

                      <Td>
                        {element.createApplicable === "1" && (
                          <Checkbox
                            

                            defaultIsChecked={
                              element.RcreatePriv === "1" ? true : false
                            }

                            onChange={(event) => {
                              const  newpreVilageLists = [...preVilageLists];
                              newpreVilageLists[index].createPriv = event.target.checked;
                              setpreVilageLists(newpreVilageLists);
                                props.setFeatures(preVilageLists);
                              }}
                          >
                            Add
                          </Checkbox>
                        )}
                      </Td>

                      <Td>
                        {element.editApplicable === "1" && (
                          <Checkbox
                            defaultIsChecked={
                              element.ReditPriv === "1" ? true : false
                            }

                            onChange={(event) => {
                              const  newpreVilageLists = [...preVilageLists];
                              newpreVilageLists[index].editPriv = event.target.checked;
                              setpreVilageLists(newpreVilageLists);
                                props.setFeatures(preVilageLists);
                              }}
                          >
                            Edit
                          </Checkbox>
                        )}
                      </Td>

                      <Td>
                        {element.deleteApplicable === "1" && (
                          <Checkbox
                            

                            defaultIsChecked={
                              element.RdeletePriv === "1" ? true : false
                            }

                            onChange={(event) => {
                              const  newpreVilageLists = [...preVilageLists];
                              newpreVilageLists[index].deletePriv = event.target.checked;
                              setpreVilageLists(newpreVilageLists);
                                props.setFeatures(preVilageLists);
                              }}
                          >
                            Delete
                          </Checkbox>
                        )}
                      </Td>

                      <Td>
                        {element.disableApplicable === "1" && (
                          <Checkbox
                            

                            defaultIsChecked={
                              element.RdisablePriv === "1" ? true : false
                            }

                            onChange={(event) => {
                              const  newpreVilageLists = [...preVilageLists];
                              newpreVilageLists[index].disablePriv = event.target.checked;
                              setpreVilageLists(newpreVilageLists);
                                props.setFeatures(preVilageLists);
                              }}
                          >
                            Active / InActive
                          </Checkbox>
                        )}
                      </Td>
                    </Tr>
                  );
                })}


                
            </Tbody>
          </Table>
        </Flex>
      </Box>
    </>
  );


};
