/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";

import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { fetchUserLists } from "../../Helper/Courselists.helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManageUserBreadCum } from "./Manageuserbreadcum";

export const UserLists = () => {
  const [userlists, setUserLists] = useState(null);
  const [searchBytext, setsearchBytext] = useState("");
  const storeDispacher = useDispatch();
  const toast = useToast();
  const accessLabel = useSelector((state) => state.user_access);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserLists(setUserLists);
  }, []);

  return (
    <>
      <ManageUserBreadCum />

      <Box p={4}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Login Name</Th>
              <Th>Description</Th>
              <Th>Phone Number</Th>
              <Th>Email Id</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userlists !== null &&
              Number.parseInt(userlists.length) >= 1 &&
              userlists.map((element, array_index) => {
                return (
                  <Tr>
                    <Td>
                      <Text>{element.userFullname}</Text>
                    </Td>
                    <Td>
                      <Text>{element.loginEmail}</Text>
                    </Td>
                    <Td>
                      <Text>{element.userDescription}</Text>
                    </Td>
                    <Td>
                      <Text>{element.userPhone}</Text>
                    </Td>

                    <Td>
                      <Text>{element.userEmail}</Text>
                    </Td>

                    <Td>
                      <HStack mt={"10px"}>
                        {accessLabel !== null &&
                          accessLabel["user"] !== null &&
                          accessLabel["user"]["editPriv"] !== null &&
                          accessLabel["user"]["editPriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Edit Course"
                              icon={<EditIcon />}
                              onClick={() => {
                                // storeDispacher()

                                storeDispacher({
                                  type: "save_user_date",
                                  userData: element,
                                });

                                navigate("/createuseres", {
                                  state: "",
                                });
                              }}
                              // mx={2}
                            />
                          )}

                        {accessLabel !== null &&
                          accessLabel["user"] !== null &&
                          accessLabel["user"]["editPriv"] !== null &&
                          accessLabel["user"]["editPriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Delete Course"
                              icon={<DeleteIcon />}
                              onClick={() => {}}
                              // mx={2}
                            />
                          )}

                        {accessLabel !== null &&
                          accessLabel["user"] !== null &&
                          accessLabel["user"]["deletePriv"] !== null &&
                          accessLabel["user"]["deletePriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Inactive Course"
                              onClick={() => {}}
                              icon={
                                Number.parseInt(element.isActive) === 1 ? (
                                  <ViewOffIcon />
                                ) : (
                                  <ViewIcon />
                                )
                              }
                              // mx={2}
                            />
                          )}
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
