/* eslint-disable */
import { useState, React, useEffect } from "react";
import { ManageUserBreadCum } from "./Manageuserbreadcum";

import {
  Box,
  Button,
  Flex,
  Select,
  Image,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import {
  updateUserHandler,
  createUserHandler,
} from "../../Helper/Courselists.helper";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchRolls,
  fetchDefaultRollss,
} from "../../Helper/Courselists.helper";

import { Select as MultiSelect } from "chakra-react-select";

export const CreateUser = () => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const editUserFromData = useSelector((state) => state.userData);

  console.log(editUserFromData);

  const [iszoomVisible, setZoomVisible] = useState(false);

  
  
  const [userCreationFrom, setUserCreationFrom] = useState({
    userId: editUserFromData ? editUserFromData.userId : "0",
    userName: editUserFromData ? editUserFromData.userFullname : "",
    loginUserName: editUserFromData ? editUserFromData.loginEmail : "",
    loginPassword: "",
    phoneNumber: editUserFromData ? editUserFromData.userPhone : "",
    userEmail: editUserFromData ? editUserFromData.userEmail : "",
    userZoomEmailId: editUserFromData ? editUserFromData.userZoomEmail : "",
    userDescription: editUserFromData ? editUserFromData.userDescription : "",
    userRollType: editUserFromData ? editUserFromData.userType : "",
    userModules: "",
    profileImage: editUserFromData ? editUserFromData.userProfilePicUrl : "",
    userProfilePictureFile: "",
  });

  

  const handleChangeforModules = (newValue, actionMeta) => {
    setUserCreationFrom((oldstate) => {
      return {
        ...oldstate,
        userModules: newValue,
      };
    });
  };

  const [userRollType, setuserRollType] = useState([
    {
      id: 2,
      rollType: "User",
    },
  ]);

  const [userDefaultRolls, setuserDefaultRolls] = useState(null);

  const toast = useToast();

  const [moduleAccessRollType, setmoduleAccessRollType] = useState(null);
  const [mulreload, setMulReload] = useState(false);

  useEffect(() => {
    const returnval = fetchRolls(setmoduleAccessRollType);
    if (returnval) {
      if (userCreationFrom.userId === "0") {
      } else {
        fetchDefaultRollss(setuserDefaultRolls, userCreationFrom.userId);
      }
    }
  }, []);

  useEffect(() => {
    if (userDefaultRolls != null) {
     
      setMulReload(!mulreload);
      if(userCreationFrom!==undefined && userCreationFrom.userZoomEmailId!==undefined  && userCreationFrom.userZoomEmailId.length>=1){
        setZoomVisible(true);
      }
      
    } else {
      setMulReload(!mulreload);
     
    }
  }, [userDefaultRolls]);

  useEffect(() => {
   
  }, [mulreload]);

  function onSubmit(values) {
    return new Promise((resolve) => {
      //  console.log(userCreationFrom);

      if (userCreationFrom.userModules.length === 0) {
        toast({
          position: "bottom-left",
          title: "Notification",
          description: "Please select modules.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });

        reset();

        return false;
      }

      if (editUserFromData && editUserFromData.userId) {
        const returnval = updateUserHandler(userCreationFrom, reset,toast);
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "User  successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });

          reset();
        }
      } else {
        const returnval = createUserHandler(userCreationFrom, reset,toast);

        reset();
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "User successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  }

  const resetFrom = ()=>{

    setUserCreationFrom({
      userId:  "0",
      userName: "",
      loginUserName:  "",
      loginPassword: "",
      phoneNumber:  "",
      userEmail:  "",
      userZoomEmailId:  "",
      userDescription:  "",
      userRollType:  "",
      userModules: "",
      profileImage:  "",
      userProfilePictureFile: "",
    });

    setuserDefaultRolls([]);
    setMulReload(false);

  }

  return (
    <>
      <ManageUserBreadCum  userId={userCreationFrom.userId} rsetFrom={resetFrom} />

      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction={"column"}>
            <Flex direction={"rows"}>
              <FormControl isInvalid={errors.userName} m={"10px"}>
                <FormLabel htmlFor="userName">Name</FormLabel>
                <Input
                  id="userName"
                  name="userName"
                  placeholder="Name"
                  {...register("userName", {
                    required: "Please enter name",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        userName: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.userName}
                />
                <FormErrorMessage>
                  {errors.userName && errors.userName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.user_name} m={"10px"}>
                <FormLabel htmlFor="login_user_name">Login User Name</FormLabel>
                <Input
                  id="login_user_name"
                  name="login_user_name"
                  placeholder="Login User Name"
                  {...register("login_user_name", {
                    required: "Please enter login user name",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        loginUserName: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.loginUserName}
                />
                <FormErrorMessage>
                  {errors.login_user_name && errors.login_user_name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.user_name} m={"10px"}>
                <FormLabel htmlFor="user_login_password">
                  Login Password
                </FormLabel>
                <Input
                  id="user_login_password"
                  name="user_login_password"
                  placeholder="Login Password"
                  {...register("user_login_password", {
                    required:
                      userCreationFrom.userId === "0"
                        ? "Please enter login password"
                        : false,
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        loginPassword: event.target.value,
                      };
                    });
                  }}
                  type="password"
                  value={userCreationFrom.loginPassword}
                />
                <FormErrorMessage>
                  {errors.login_user_name && errors.login_user_name.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex>
              <FormControl isInvalid={errors.user_name} m={"10px"}>
                <FormLabel htmlFor="phone_number">
                  Phone Number (Without Country Code)
                </FormLabel>
                <Input
                  id="phone_number"
                  name="phone_number"
                  placeholder="Phone Number"
                  {...register("phone_number", {
                    required: "Please phone number",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        phoneNumber: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.phoneNumber}
                />
                <FormErrorMessage>
                  {errors.phone_number && errors.phone_number.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.user_name} m={"10px"}>
                <FormLabel htmlFor="user_email">Email Id</FormLabel>
                <Input
                  id="user_email"
                  name="user_email"
                  placeholder="Email"
                  {...register("user_email", {
                    required: "Please enter email",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        userEmail: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.userEmail}
                />
                <FormErrorMessage>
                  {errors.user_email && errors.user_email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.user_zoom_emilid} m={"10px"}>
                <FormLabel htmlFor="user_zoom_email">
                  {iszoomVisible ? <span>&nbsp;</span> : <span>&nbsp;</span>}{" "}
                  <Checkbox
                    onChange={(event) => {
                      setZoomVisible(event.target.checked);
                    }}
                    defaultChecked = {userCreationFrom!==undefined && userCreationFrom.userZoomEmailId!==undefined && userCreationFrom.userZoomEmailId.length>=1?true:false}

                  >
                    Enable Zoom ID
                  </Checkbox>
                </FormLabel>
                <Input
                  id="user_zoom_email"
                  name="user_zoom_email"
                  placeholder="Zoom Email"
                  {...register("user_zoom_email", {
                    required: "Please enter zoom email",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        userZoomEmailId: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.userZoomEmailId}
                  style={{ display: `${iszoomVisible ? "" : "none"}` }}
                />
                <FormErrorMessage
                  style={{ display: `${iszoomVisible ? "" : "none"}` }}
                >
                  {errors.user_zoom_emilid && errors.user_zoom_emilid.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex>
              <FormControl isInvalid={errors.user_description} m={"10px"}>
                <FormLabel htmlFor="user_description">Description</FormLabel>
                <Textarea
                  id="user_description"
                  name="user_description"
                  placeholder="Description"
                  {...register("user_description", {})}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        userDescription: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.userDescription}
                />
                <FormErrorMessage>
                  {errors.user_description && errors.user_description.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex>
              <FormControl isInvalid={errors.course_select_tyep} m={"10px"}>
                <FormLabel htmlFor="course_select_tyep">User Type</FormLabel>
                <Select
                  key="seletc1"
                  name="course_select_roll"
                  isDisabled={
                    userCreationFrom && userCreationFrom.id ? true : false
                  }
                  placeholder="Select User Type"
                  {...register("course_select_tyep", {
                    required:
                      userCreationFrom && userCreationFrom.id
                        ? false
                        : "Please select role",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        userRollType: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.userRollType}
                >
                  {userRollType.map((ele) => {
                    return (
                      <option key={ele.id} value={ele.id}>
                        {ele.rollType}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {errors.user_roll_type && errors.user_roll_type.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.course_select_tyep} m={"10px"}>
                <FormLabel htmlFor="course_select_tyep">
                  Role ( Multiple Role can be Selected )
                </FormLabel>

                {mulreload ||
                  !mulreload && (
                    <MultiSelect
                      isMulti
                      name="colors"
                      options={moduleAccessRollType}
                      closeMenuOnSelect={false}
                      onChange={handleChangeforModules}
                      defaultValue={userDefaultRolls}
                    ></MultiSelect>
                  )}

                <FormErrorMessage>
                  {errors.user_roll_type && errors.user_roll_type.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex flexDirection={"row"}>
              {userCreationFrom.profileImage ? (
                <FormControl m={"10px"}>
                  <FormLabel>Uploaded Profile Picture</FormLabel>

                  {userCreationFrom.profileImage.length >= 1 ? (
                    <Image src={userCreationFrom.profileImage} h={"120px"} />
                  ) : (
                    <Text></Text>
                  )}
                </FormControl>
              ) : (
                <Text></Text>
              )}

              <FormControl isInvalid={errors.profile_picture} m={"10px"}>
                <FormLabel htmlFor="upload_course_picture">
                  Profile Picture
                </FormLabel>

                <input
                  type="file"
                  name="upload_profile_picture"
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        userProfilePictureFile: event.target.files[0],
                      };
                    });
                  }}
                />
              </FormControl>
            </Flex>

            <Flex flexDirection={"row"}>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>

              <Button mt={4} colorScheme="teal" type="button" ml={10}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
};
