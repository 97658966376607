/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ChevronRightIcon, QuestionIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

export const ManageUserBreadCum = (props) => {

 const accessLabel =  useSelector((state)=>state.user_access);


  
  const [showpageButton, setshowpageButton] = useState(true);
  const [showbackbutton, setshowbackbutton] = useState(true);
  const [manageRollButton, setmanageRollButton] = useState(false);

  useEffect(() => {
    setshowbackbutton(false);
    if (window.location.pathname === "/createuseres") {
      setshowpageButton(true);
    } else if (window.location.pathname === "/listsusers") {
      setshowpageButton(false);
      setmanageRollButton(true);
    } else if (window.location.pathname === "/manageroll") {
      setshowpageButton(false);
      setmanageRollButton(false);
      setshowbackbutton(false);
    } else {
      setshowbackbutton(true);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <Flex>
      <Breadcrumb
        width="100%"
        padding={5}
        spacing="8px"
        position="sticky"
        top="0px"
        backgroundColor={"white"}
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        {accessLabel !== null &&
  accessLabel["user"] !== null &&
  accessLabel["user"]["viewPriv"] !== null &&
  accessLabel["user"]["viewPriv"] === "1" && (
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/listsusers">
            List Admin
          </BreadcrumbLink>
        </BreadcrumbItem>
  )}

        {accessLabel !== null &&
  accessLabel["user"] !== null &&
  accessLabel["user"]["createPriv"] !== null &&
  accessLabel["user"]["createPriv"] === "1" && (
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/createuseres" onClick={()=>{

            if(props!=null && props!=undefined){

              if(props.userId==="0"){

              }else{
                try{
                  props.rsetFrom();
                }catch(edd){

                }
               
              }

            }
            
          }}>
            Create Admin
          </BreadcrumbLink>
        </BreadcrumbItem>
  )}


      </Breadcrumb>

      <Spacer />

      {manageRollButton && (
        <>
          <Tooltip
            label="Manage Role helps you to edit/delete or activate create roles for admin"
            fontSize="md"
          >
            <QuestionIcon w={6} h={6} mt={7} mr={3}  />
          </Tooltip>


          
 {accessLabel !== null &&
  accessLabel["role"] !== null &&
  accessLabel["role"]["viewPriv"] !== null &&
  accessLabel["role"]["viewPriv"] === "1" && (

          <Button
            colorScheme="teal"
           
            mt={5}
            mr={15}
            onClick={() => {
              navigate("/manageroll", {
                state: JSON.stringify({}),
              });
            }}
          >
            Manage Role
          </Button>

  )}
        </>
      )}

      {showpageButton && (
        <>
          <Tooltip
            label="Create role helps you to create role for admin"
            fontSize="md"
          >
            <QuestionIcon w={6} h={6} mt={7} mr={3}  />
          </Tooltip>
          <Button
            colorScheme="teal"
           
            mt={5}
            mr={15}
            onClick={() => {
              navigate("/createroll", {
                state: JSON.stringify({}),
              });
            }}
          >
            Create Role
          </Button>

          {showbackbutton && (
            <Button
              colorScheme="teal"
              mt={5}
              mr={29}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
          )}
        </>
      )}
    </Flex>
  );
};
