/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";

import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { fetchUserNotAdmin } from "../../Helper/Courselists.helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManageUserBreadCum } from "./Manageuserbreadcum";
import { UserListsBreadcome } from "./UserListsBreadcome";

export const Otherusereslists = () => {
  const [userlists, setUserLists] = useState([]);
  const [searchBytext, setsearchBytext] = useState("");
  const storeDispacher = useDispatch();
  const toast = useToast();
  const accessLabel = useSelector((state) => state.user_access);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserNotAdmin(setUserLists);
  }, []);

  useEffect(()=>{

    console.log("Data Recived 3");
    console.log(userlists);

  },[userlists]);

  return (
    <>

<UserListsBreadcome />
     
      <Box p={4}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone Number</Th>
              <Th>Email Id</Th>
              <Th>Course Name</Th>
              <Th>Course Progress</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userlists !== undefined &&
              Number.parseInt(userlists.length) >= 1 &&
              userlists.map((element, array_index) => {
                return (
                  <Tr key={array_index}>
                    <Td>
                      <Text>{element.userFullname}</Text>
                    </Td>
                    <Td>
                      <Text>{element.loginPhone}</Text>
                    </Td>

                    <Td>
                      <Text>{element.userEmail}</Text>
                    </Td>

                    <Td>
                      <Text>{element.course_name} </Text>
                    </Td>

                    <Td>
                      <Text>{element.course_completion_percent}% </Text>
                    </Td>

                  
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
