/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Text,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spacer,
  Center,
  Button,
} from "@chakra-ui/react";
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";

import { ModuleBreadcum } from "./Modulebreadcum";
import {
  loadAllModules,
  inactiVeCourse,
  deleteCourse,
  addModuleIntoCourse,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlertDialog } from "../../component/Customalert.dialog";
import { useDispatch, useSelector } from "react-redux";
import { CourseBreadcum } from "../Createcourse/CourseBreadcum";

export const Listsallmodules = () => {
  const course_data = useSelector((state) => state.course_data);
  const moduledispacher = useDispatch();

 

  
  
  const course_id = course_data ? course_data.id : "0";

  //console.log(course_id);

  const perPageConstant = 50;
  const [moduleLists, setModuleLists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
  const [showdialog, setshowdialog] = useState(false);
  const [indexCourse, setindexCourse] = useState(-1);

  

  const toast = useToast();

  const navigate = useNavigate();

  const cancelAlert = () => {
    setshowdialog(!showdialog);
  };

  const deleteAlert = () => {
    setshowdialog(!showdialog);
    let temp_array = [...moduleLists];
    console.log(indexCourse);
    const returnResponse = deleteCourse(
      setModuleLists,
      temp_array,
      indexCourse
    );

    if (returnResponse) {
      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Course deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const addModuleIntoCourseHandler =(presentmodule)=>{

    

    let temp_array = [...moduleLists];
    const returnResponse = addModuleIntoCourse(
      setModuleLists,
      temp_array,
      presentmodule.id,
      course_data.id,
    );

    if (returnResponse) {

      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Module Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

    }

  }

  function searchBytext(item) {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) ||
      searchPattern.test(item.courseAuthor) ||
      searchPattern.test(item.sectorName)
    );
  }

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  useEffect(() => {
    // await
    loadAllModules(setModuleLists, course_data ? course_data.id : "0");
    // setcourseLsistsPagination(courseLsists.slice(pageStart, perPage));
  }, []);

 if( Number.parseInt(moduleLists.length) === 0)
 {

           return (
               'No Records'

           );
  }

  return (
    <>

<CourseBreadcum  courseData={course_data} />
      
      <Flex flexDirection={"columns"}>
        <Text p="2" pl="15" mt="4" >
           
        </Text>
        <Spacer />

        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setcourseLsists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <CustomAlertDialog
          show={showdialog}
          cancelAlert={cancelAlert}
          deleteAlert={deleteAlert}
        />
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Lessons Count</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(moduleLists.length) >= 1 &&
              moduleLists.filter(searchBytext).map((element) => (
                <Tr key={element.id}>
                  <Td>
                    <Text w={190}>{element.name}</Text>
                  </Td>
                  <Td>
                    <Text>{element.lesson_count}</Text>
                  </Td>
                  <Td>
                    <HStack mt={"10px"}>
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Add Module"
                        icon={<AddIcon/>}
                        onClick={() => {
                          if (true) {
                            const array_position = findSeletedItemPosition(
                              moduleLists,
                              element
                            );

                          //  setPresentModuleHandler(element);
                          addModuleIntoCourseHandler(element);
                          }
                        }}
                        // mx={2}
                      />
                      
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {(moduleLists.filter(searchBytext).length > perPageConstant) & false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={moduleLists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};


