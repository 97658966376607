/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
  Button,
  Link,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
  CopyIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import Moment from "react-moment";

import {
  loadLivemeetings,
  inactiVeMeeting,
  deleteCourse,
  timeDistance,
  copyMeetingHandler,
  deleteMeeting,
  loadLessionLists,
  loadPaymentDetails,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlertDialog } from "../../component/Customalert.dialog";
import { CustomAlert } from "../../component/Customalert";
import { Customalertyesno } from "../../component/Customalertyesno";

import { useDispatch } from "react-redux";
import { ManagePaymentBreadcum } from "./ManagePaymentBreadcum";

export const ManagePayment = () => {
  const perPageConstant = 50;
  const [liveLessionLists, setliveLessionLists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
  const [reloadtable, setReloadtable] = useState(true);

  const [indexCourse, setindexCourse] = useState(-1);
  const storeDispacher = useDispatch();

  const toast = useToast();

  const navigate = useNavigate();

  const [showdialog, setShowdialog] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const [showdialogyesno, setShowdialogYesNo] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const cancelAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });
  };

  const deleteAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });

    if (true) {
      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Meeting copyed successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const searchBytext = (item) => {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.studentName) ||
      searchPattern.test(item.trainerName) ||
      searchPattern.test(item.courseName)  ||
      searchPattern.test(item.paymentType) 
    );
  };

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  useEffect(() => {
    loadPaymentDetails(setliveLessionLists);
  }, [reloadtable]);

  return (
    <>
      <Flex flexDirection={"columns"}>
        <ManagePaymentBreadcum seleted_item="liveclassess" />

      
        <Spacer />
        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setliveLessionLists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Student Name</Th>
              <Th>Trainer Name</Th>
              <Th>Course Name</Th>
              <Th>Course Amount</Th>
              <Th>Amount Paid</Th>
              <Th>Payment Date</Th>
              <Th>Payment Mode</Th>
              <Th>Transation Id</Th>
              <Th>Payment Status</Th>
             
              
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(liveLessionLists.length) >= 1 &&
              liveLessionLists.filter(searchBytext).map((element, index) => {
                //  const start_date = Date.parse(element.topic_start_time);
                //  const end_date = Date.parse(element.topic_duration);

                //  const date_diff = timeDistance(end_date, start_date);

                const start_time_1 = Date.parse(
                  element.live_class_date + " " + element.topic_start_time
                );

                return (
                  <Tr key={index}>
                    <Td>
                    <Text >{element.studentName}</Text>
                    </Td>
                    <Td>
                    <Text >{element.trainerName}</Text>
                    </Td>
                    <Td>
                    <Text w={120} >{element.courseName}</Text>
                    </Td>
                    <Td>
                    <Text >{element.courseAmount}</Text>
                    </Td>
                    <Td>
                    <Text >{element.amountPaid}</Text>
                    </Td>

                    <Td>
                    <Moment format="HH:mm A DD/MM/YYYY">
                        {element.paymentDate}
                    </Moment>
                    </Td>

                    <Td>
                    <Text >{element.paymentMode}</Text>
                    </Td>

                    <Td>
                    <Text >{element.transationId}</Text>
                    </Td>

                    <Td>
                    <Text >{element.paymentType}</Text>
                    </Td>
                    
                    
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      {(liveLessionLists.filter(searchBytext).length > perPageConstant) &
      false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={liveLessionLists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};
