/* eslint-disable */
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spacer,
  Button,
  propNames,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export function ManageLessionBreadcum({ seleted_item, resetState }) {
  const navigateField = useNavigate();
  const moduledispatcher = useDispatch();
  const { state } = useLocation();
  // console.log(JSON.parse(state))

  const accessLabel = useSelector((state) => state.user_access);
  let referData = state ? JSON.parse(state) : null;

  const pagerefername = referData ? referData.refere_from : "";

  return (
    <>
      <Flex>
        {pagerefername !== null && pagerefername !== "createmodule" && (
          <Breadcrumb
            width="100%"
            padding={5}
            spacing="8px"
            position="sticky"
            top="0px"
            backgroundColor={"white"}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            {accessLabel !== null &&
              accessLabel["lesson"] !== null &&
              accessLabel["lesson"]["viewPriv"] !== null &&
              accessLabel["lesson"]["viewPriv"] === "1" && (
                <BreadcrumbItem
                  isCurrentPage={seleted_item === "managelessionlists"}
                >
                  <BreadcrumbLink as={RouterLink} to="/managelessionlists">
                    Lists
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}

            {accessLabel !== null &&
              accessLabel["lesson"] !== null &&
              accessLabel["lesson"]["createPriv"] !== null &&
              accessLabel["lesson"]["createPriv"] === "1" && (
                <BreadcrumbItem
                  isCurrentPage={seleted_item === "createlession"}
                >
                  <BreadcrumbLink
                    as={RouterLink}
                    to="/createlession"
                    onClick={() => {
                      if (window.location.pathname === "/managelessionlists") {
                      } else {
                        moduledispatcher({
                          type: "create_meeting_data",
                          create_meeting_form: null,
                        });

                        resetState();
                      }
                    }}
                  >
                    Create lesson
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
          </Breadcrumb>
        )}

        <Spacer />

        {pagerefername !== null && pagerefername === "createmodule" && (
          <Flex mt="4" mr="4">
            <Button
              colorScheme="teal"
              ml="10"
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
}
