/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
  AddIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";

import {
  loadCourseLists,
  inactiVeCourse,
  deleteCourse,
  loadStudentForCreateMeetins,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlertDialog } from "../../component/Customalert.dialog";
import { useDispatch, useSelector } from "react-redux";
import { LiveclassessBreadcum } from "./LiveclassessBreadcum";

export const Listsaddstudentforliveclass = () => {
  const perPageConstant = 50;

  const toastmessage = useToast();

  const addStudentDispatcherHandler = useDispatch();
  let studentlists = useSelector((state) => state.meeting_student_lists);

  const [courseLsists, setcourseLsists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
  const [showdialog, setshowdialog] = useState(false);
  const [indexCourse, setindexCourse] = useState(-1);
  const storeDispacher = useDispatch();

  const toast = useToast();

  const navigate = useNavigate();

  function searchBytext(item) {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) ||
      searchPattern.test(item.courseAuthor) ||
      searchPattern.test(item.sectorName)
    );
  }

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  useEffect(() => {
    loadStudentForCreateMeetins(setcourseLsists);

    //console.log(studentlists);
  }, []);

  return (
    <>
      <LiveclassessBreadcum seleted_item="Listsaddstudentforliveclass" />
      <Flex flexDirection={"columns"}>
        <Spacer />
        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setcourseLsists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Student Name</Th>

              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(courseLsists.length) >= 1 &&
              courseLsists.filter(searchBytext).map((element, index) => (
                <Tr key={`a${index}`}>
                  <Td>
                    <Text>{element.name}</Text>
                  </Td>

                  <Td>
                    <HStack mt={"10px"}>
                      {studentlists.find(({ id }) => id === element.id) ? (
                        ""
                      ) : (
                        <IconButton
                          variant="outline"
                          colorScheme="teal"
                          aria-label="Add Student"
                          icon={<AddIcon />}
                          onClick={() => {
                            addStudentDispatcherHandler({
                              type: "add_student_into_meeting",
                              meeting_student_lists: [...studentlists, element],
                            });

                            toast({
                              title: "Student added.",
                              description:
                                "Student added successfully into meeting lists",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                              position: "bottom-left",
                            });
                          }}
                          // mx={2}
                        />
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {(courseLsists.filter(searchBytext).length > perPageConstant) & false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={courseLsists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};
