/* eslint-disable */
import { React, useEffect, useState } from "react";
import {
  Box,
  Button,
  useColorModeValue,
  Stack,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const ViewCourseProgressBox = () => {
  const navigate = useNavigate();
  const [manageCourseBox, setManageCourseBox] = useState({
    activecourse: "0",
    inactivecourse: "0",
  });

  useEffect(() => {}, []);

  return (
    <>
      <Box
        maxW={"270px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"md"}
        overflow={"hidden"}
      >
        <Box p={6}>
          <Stack spacing={0} align={"center"} mb={5}>
            <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
             View User Lists
            </Heading>
            <Text color={"gray.500"}>
              This section used view and analysics course progress of individual students
            </Text>
          </Stack>
          <Stack direction={"row"} justify={"center"} spacing={6} style={{ display:'none',}}>
            <Stack spacing={0} align={"center"}>
              <Text fontWeight={600}>{manageCourseBox.activecourse}</Text>
              <Text fontSize={"sm"} color={"gray.500"}>
                Active
              </Text>
            </Stack>
            <Stack spacing={0} align={"center"}>
              <Text fontWeight={600}>{manageCourseBox.inactivecourse}</Text>
              <Text fontSize={"sm"} color={"gray.500"}>
                InActive
              </Text>
            </Stack>
          </Stack>
          <Button
            w={"full"}
            mt={8}
            bg={useColorModeValue("#151f21", "gray.900")}
            color={"white"}
            rounded={"md"}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            onClick={() => {
              navigate("/otherusermanage");
            }}
          >
          View User Lists
          </Button>
        </Box>
      </Box>
    </>
  );
};
