/* eslint-disable */
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export function LiveclassessBreadcum({
  seleted_item,
  courseData,
  createNewClassOnline,
}) {
  const navigateField = useNavigate();
  const moduledispatcher = useDispatch();

  const accessLabel = useSelector((state) => state.user_access);
  useEffect(() => {
    console.log("Few Error");
    console.log(accessLabel);
  }, []);

  let topButtons = "";

  if (seleted_item === "liveclassess") {
    topButtons = "";
  } else {
    topButtons = (
      <Flex mt="4" mr="4">
        <Button
          colorScheme="teal"
          onClick={() => {
            navigateField(
              `/${
                seleted_item === "Listsaddstudentforliveclass"
                  ? "createliveclassess"
                  : "listsaddstudents"
              }`
            );
          }}
        >
          {seleted_item === "Listsaddstudentforliveclass"
            ? "Back"
            : "Add Student"}
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Flex>
        <Breadcrumb
          width="100%"
          padding={5}
          spacing="8px"
          position="sticky"
          top="0px"
          backgroundColor={"white"}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          {accessLabel !== null &&
            accessLabel["online_course"] !== null &&
            accessLabel["online_course"]["viewPriv"] !== null &&
            accessLabel["online_course"]["viewPriv"] === "1" && (
              <BreadcrumbItem
                isCurrentPage={seleted_item === "listsliveclassess"}
              >
                <BreadcrumbLink as={RouterLink} to="/listsliveclassess">
                  Lists
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}


{accessLabel !== null &&
            accessLabel["online_course"] !== null &&
            accessLabel["online_course"]["createPriv"] !== null &&
            accessLabel["online_course"]["createPriv"] === "1" && (

          <BreadcrumbItem isCurrentPage={seleted_item === "createliveclassess"}>
            <BreadcrumbLink
              as={RouterLink}
              to="/createliveclassess"
              onClick={() => {
                if (window.location.pathname === "/listsliveclassess") {
                } else {
                  createNewClassOnline();
                }
              }}
            >
              Create Live class
            </BreadcrumbLink>
          </BreadcrumbItem>

            )}
        </Breadcrumb>

        <Spacer />

        {topButtons}
      </Flex>
    </>
  );
}
