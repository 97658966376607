/* eslint-disable */
import { useLoginContext } from "../context/Logincontextprovider";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { AlertDialogExample } from "./Customalert.dialog";
import { useEffect, useState } from "react";

const Links = [];

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={"#"}
  >
    {children}
  </Link>
);

export function Dashboardnavigation() {
  const navigate = useNavigate();
  const { isUserLogin, setUserLogin } = useLoginContext();
  
  const [usernameDisplay, setshowuserName] = useState("No Name");

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(()=>{
   // alert(localStorage.getItem('userData'));
    setshowuserName(localStorage.getItem('userFullname'))
  //console.log("Present User Data");
 //  console.log(localStorage.getItem('userFullname'));
  },[]);

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <Box>
            <Image src={"https://orionedutech.co.in/fms/images/logo.png"}
                 
                   h={20}
                   p={4}
               />


          </Box>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          <Menu>
          <HStack>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              
              <Avatar
                size={"sm"}
                src={
                  "assets/profile.png"
                }
              />

           

             
              


            </MenuButton>
            <Text>{`${usernameDisplay}`}</Text>
            </HStack>
            <MenuList>
              <MenuItem>
                <Link
                  onClick={() => {
                    localStorage.removeItem("userLogedIn");
                    localStorage.removeItem("session_token");

                    setUserLogin(false);
                    navigate("/");
                  }}
                >
                  Log Out
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
