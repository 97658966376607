/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";

import {
  loadCourseLists,
  inactiVeCourse,
  deleteCourse,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlert } from "../../component/Customalert";
import { useDispatch } from "react-redux";

export function Listsliveclassstudents(props) {
  const perPageConstant = 50;
  let studentlists = props.studentlists;
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
 
  const [indexCourse, setindexCourse] = useState(-1);
  const storeDispacher = useDispatch();

  const toast = useToast();

  const navigate = useNavigate();

 


  function searchBytext(item) {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) ||
      searchPattern.test(item.courseAuthor) ||
      searchPattern.test(item.sectorName)
    );
  }

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  const [showdialog, setShowdialog] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const cancelAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });
  };

  const deleteAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });

    let temp_array = [...studentlists];
    temp_array.splice(showdialog.array_position, 1);
   // setstudentlists(temp_array);
    props.setStudent(temp_array);

    toast({
      title: 'Student removed.',
      description: "Student removed successfully from meeting lists",
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'bottom-left',
    });

    
  };
  

  useEffect(() => {
    // await
    //  loadCourseLists(setstudentlists, searchText);
    // setstudentlistsPagination(studentlists.slice(pageStart, perPage));
  }, []);

  return (
    <>
      <Flex flexDirection={"columns"}>
        <Spacer />
        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setstudentlists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <CustomAlert
          show={showdialog.show}
          title={showdialog.title}
          description={showdialog.description}
          cancelAlert={cancelAlertHandler}
          deleteAlert={deleteAlertHandler}
        />
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Student Name</Th>

              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(studentlists.length) >= 1 &&
              studentlists.filter(searchBytext).map((element, index) => (
                <Tr key={element.id}>
                  <Td>
                    <Text>{element.name}</Text>
                  </Td>

                  <Td>
                    <HStack mt={"10px"}>
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Delete Course"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          if (true) {
                            setShowdialog((oldState) => {
                              return {
                                ...oldState,
                                show: true,
                                store_data: element,
                                array_position: index,
                                title: "Delete Item Notification",
                                description:
                                  "Do you want to delete this student from the live class?",
                              };
                            });
                          }
                        }}
                        // mx={2}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {(studentlists.filter(searchBytext).length > perPageConstant) & false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={studentlists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
}
