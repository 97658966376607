/* eslint-disable */
import { useState, React, useEffect } from "react";


import {
  Box,
  Button,
  Flex,
  Select,
  Image,
  Text,
  Checkbox,

  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import {
  updateUserHandler,
  createUserHandler,
} from "../../Helper/Courselists.helper";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchRolls,
  fetchNurchingRegisteredStudent,
  fetchDefaultRollss,
  updateNurSingStatus,
} from "../../Helper/Courselists.helper";

import { ManageUserBreadCum } from "./ManagestudentBreadcum";
import { Select as MultiSelect } from "chakra-react-select";

export const EditRegisterNurSingStudent = () => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const editUserFromData = useSelector((state) => state.nurSingStudents);

  const [genderLists, setgenderLists] = useState([{
    "id":"1",
    "name":"Female",
},
{
  "id":"2",
  "name":"Male",
},
{
  "id":"3",
  "name":"Trans",
}
]);

const [qualification, setqualification] = useState([{
  "id":"1",
  "name":"BSC",
},
{
"id":"2",
"name":"GNM",
}
]);

const [whyGermanNurse, setwhyGermanNurse] = useState([{
  "id":"1",
  "name":"HIGH DEMAND FOR HEALTHCARE WORKERS",
},
{
"id":"2",
"name":"EXECELLENT HUMAN DEVELOPMENT INDEXAND A GREAT STANDARD OF LIVING",
}
,
{
"id":"3",
"name":"DIVERSE COMMUNITY",
}
,
{
"id":"4",
"name":"HIGH INCOME OPPORTUNITY",
}
,
{
"id":"5",
"name":"SECURED EMPLOYMENT AND SOCIAL SECURITY",
}
]);

const [isGermanLang, setisGermanLang] = useState([{
  "id":"1",
  "name":"Yes",
},
{
"id":"0",
"name":"No",
}
]);

const [iadmFormStatus, setiadmFormStatus] = useState([
  {
  "id":"0",
  "name":"Admission Form under Review",
},
{
  "id":"1",
  "name":"Admission Form Approved",
},
{
  "id":"2",
  "name":"Admission Form Rejected",
},
{
  "id":"3",
  "name":"Admission Form Re-Submitted",
}
]);

const [qualificationLists, setqualificationLists] = useState([
  {
  "id":"0",
  "examPassed":"examPassed 1",
  "schoolCollegeName":"schoolCollegeName 1",
  "passingYear":"passingYear 1",
  "univBoard":"univBoard 1",
  "aggPercentage":"aggPercentage 1"
},
{
  "id":"1",
  "examPassed":"examPassed 2",
  "schoolCollegeName":"schoolCollegeName 2",
  "passingYear":"passingYear 2",
  "univBoard":"univBoard 2",
  "aggPercentage":"aggPercentage 2"
},
{
  "id":"2",
  "examPassed":"examPassed 3",
  "schoolCollegeName":"schoolCollegeName 3",
  "passingYear":"passingYear 3",
  "univBoard":"univBoard 3",
  "aggPercentage":"aggPercentage 3"
},
]);











  //console.log(editUserFromData);

  const [iszoomVisible, setZoomVisible] = useState(false);

  const [fetchedNursingData, setfetchedNursingData] = useState(null);


  useEffect(() => {
    // await
    fetchNurchingRegisteredStudent(setfetchedNursingData,editUserFromData);
    // setcourseLsistsPagination(courseLsists.slice(pageStart, perPage));
  }, []);

  const [userCreationFrom, setUserCreationFrom] = useState({
    enrollmentId: editUserFromData ? editUserFromData.enrollmentId : "0",
    studentName:  "",
    studentAge:  "",
    studentGender: "",
    motherName: "",
    fatherHusbandName: "",
    studentDob: "",
    placeOfBirth: "",
    nationality: "",
    motherTongue: "",
    address: "",
    pinCode: "",
    city: "",
    mobileNo: "",
    email: "",
    occupation: "",
    qualification: "",
    whyGermanNurse: "1",
    isGermanLang:"1",
    learnGermanLang:"1",
    howComeToKnow:"",
    signature:"",
    fromStatusChanged:false,
    iadmFormStatus:false,
    admFormStatusComment:false,
    educationFieldChanged:false,
  });

  useEffect(() => {

    

    if(fetchedNursingData!==undefined && fetchedNursingData!=null ){
     
      setUserCreationFrom((oldstate) => {
        return {
          ...oldstate,
          studentName:  fetchedNursingData[0].studentName,
          studentAge:  fetchedNursingData[0].studentAge,
          studentGender:  fetchedNursingData[0].studentGender,
          motherName:  fetchedNursingData[0].motherName,
          fatherHusbandName:  fetchedNursingData[0].fatherHusbandName,
          studentDob:  fetchedNursingData[0].studentDob,
          placeOfBirth:  fetchedNursingData[0].placeOfBirth,
          nationality:  fetchedNursingData[0].nationality,
          motherTongue:  fetchedNursingData[0].motherTongue,
          address:  fetchedNursingData[0].address,
          pinCode:  fetchedNursingData[0].pinCode,
          city:  fetchedNursingData[0].city,
          mobileNo:  fetchedNursingData[0].mobileNo,
          email:  fetchedNursingData[0].email,
          occupation:  fetchedNursingData[0].occupation,
          qualification:  fetchedNursingData[0].qualification,
          whyGermanNurse:  fetchedNursingData[0].whyGermanNurse,
          isGermanLang:  fetchedNursingData[0].isGermanLang,
          learnGermanLang:  fetchedNursingData[0].learnGermanLang,
          howComeToKnow:fetchedNursingData[0].howComeToKnow,
          signature: fetchedNursingData[0].signature,
          iadmFormStatus: fetchedNursingData[0].iadmFormStatus,
          admFormStatusComment:fetchedNursingData[0].admFormStatusComment,
          enrollmentId:editUserFromData.enrollmentId,
         
          
          
          
        };
      });
     /* setUserCreationFrom((oldstate) => {
        return {
          ...oldstate,
          studentName:  fetchedNursingData[0].studentName,
        };
      });*/

      setqualificationLists(fetchedNursingData);
    }
   

  }, [fetchedNursingData]);



  
  


  

  const handleChangeforModules = (newValue, actionMeta) => {
    setUserCreationFrom((oldstate) => {
      return {
        ...oldstate,
        userModules: newValue,
      };
    });
  };

  const [userRollType, setuserRollType] = useState([
    {
      id: 2,
      rollType: "User",
    },
  ]);

  const [userDefaultRolls, setuserDefaultRolls] = useState(null);

  const toast = useToast();

  const [moduleAccessRollType, setmoduleAccessRollType] = useState(null);
  const [mulreload, setMulReload] = useState(false);

  useEffect(() => {
    const returnval = fetchRolls(setmoduleAccessRollType);
    if (returnval) {
      if (userCreationFrom.userId === "0") {
      } else {
        fetchDefaultRollss(setuserDefaultRolls, userCreationFrom.userId);
      }
    }
  }, []);


  

  useEffect(() => {
    if (userDefaultRolls != null) {
     
      setMulReload(!mulreload);
      if(userCreationFrom!==undefined && userCreationFrom.userZoomEmailId!==undefined  && userCreationFrom.userZoomEmailId.length>=1){
        setZoomVisible(true);
      }
      
    } else {
      setMulReload(!mulreload);
     
    }
  }, [userDefaultRolls]);

  useEffect(() => {
   
  }, [mulreload]);

  function onSubmit(values) {
    return new Promise((resolve) => {
      //  console.log(userCreationFrom);

      

     // if (editUserFromData && editUserFromData.userId) {
       if(true){
        const returnval = updateNurSingStatus(userCreationFrom, reset,qualificationLists);
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Updated successfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });

          reset();
        }
      } else {
        const returnval = false;//createUserHandler(userCreationFrom, reset);

        reset();
        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Updated successfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  }

  const resetFrom = ()=>{

    setUserCreationFrom({
      userId:  "0",
      userName: "",
      loginUserName:  "",
      loginPassword: "",
      phoneNumber:  "",
      userEmail:  "",
      userZoomEmailId:  "",
      userDescription:  "",
      userRollType:  "",
      userModules: "",
      profileImage:  "",
      userProfilePictureFile: "",
    });

    setuserDefaultRolls([]);
    setMulReload(false);

  }

  return (
    <>

<ManageUserBreadCum />


      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction={"column"}>
            <Flex direction={"rows"}>
              <FormControl isInvalid={errors.studentName} m={"10px"}>
                <FormLabel htmlFor="studentName">Student Name</FormLabel>
                <Input
                  id="studentName"
                  name="studentName"
                  placeholder="Student Name"
                  {...register("studentName", {
                    required: userCreationFrom && userCreationFrom.studentName.length>0 ? false :  "Please enter student name",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        studentName: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.studentName}
                />
                <FormErrorMessage>
                  {errors.studentName && errors.studentName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.studentAge} m={"10px"}>
                <FormLabel htmlFor="studentAge">Student Age</FormLabel>
                <Input
                  id="studentAge"
                  name="studentAge"
                  placeholder="Student Age"
                  {...register("studentAge", {
                    required: userCreationFrom && userCreationFrom.studentAge.length>0 ? false :"Please enter student age",
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        studentAge: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.studentAge}
                />
                <FormErrorMessage>
                  {errors.studentAge && errors.studentAge.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.studentGender} m={"10px"}>
              <FormLabel htmlFor="studentGender">Gender</FormLabel>
              <Select
                key="studentGender"
                name="studentGender"
                id="studentGender"
              /*  isDisabled={userCreationFrom && Number(userCreationFrom.studentGender)>0 ? true : false} */

                placeholder="Gender"
                {...register("studentGender", {
                 
                  required: userCreationFrom && Number(userCreationFrom.studentGender)>0 ? false : "Please gender",
                 
                })}

                

                onChange={(event) => {
                  setUserCreationFrom((oldstate) => {
                    return {
                      ...oldstate,
                      studentGender: event.target.value,
                    };
                  });
                }}
                value={userCreationFrom.studentGender}
              >

                
                {genderLists.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}
              </Select>

              <FormErrorMessage>
                {errors.studentGender &&
                  errors.studentGender.message}
              </FormErrorMessage>
            </FormControl>
            </Flex>

            <Flex>
              <FormControl isInvalid={errors.motherName} m={"10px"}>
                <FormLabel htmlFor="motherName">
                Mother Name
                </FormLabel>
                <Input
                  id="motherName"
                  name="motherName"
                  placeholder="Mother Name"
                  {...register("motherName", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        motherName: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.motherName}
                />
                <FormErrorMessage>
                  {errors.motherName && errors.motherName.message}
                </FormErrorMessage>
              </FormControl>


              <FormControl isInvalid={errors.fatherHusbandName} m={"10px"}>
                <FormLabel htmlFor="fatherHusbandName">
                Father Name
                </FormLabel>
                <Input
                  id="fatherHusbandName"
                  name="fatherHusbandName"
                  placeholder="Father Name"
                  {...register("fatherHusbandName", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        fatherHusbandName: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.fatherHusbandName}
                />
                <FormErrorMessage>
                  {errors.fatherHusbandName && errors.fatherHusbandName.message}
                </FormErrorMessage>
              </FormControl>


              <FormControl isInvalid={errors.studentDob} m={"10px"}>
                <FormLabel htmlFor="studentDob">
                Date of Birth
                </FormLabel>
                <Input
                  id="studentDob"
                  name="studentDob"
                  type={"date"}
                  placeholder="Date of Birth"
                  {...register("studentDob", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        studentDob: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.studentDob}
                />
                <FormErrorMessage>
                  {errors.studentDob && errors.studentDob.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.placeOfBirth} m={"10px"}>
                <FormLabel htmlFor="placeOfBirth">
                 Place Of Birth
                </FormLabel>
                <Input
                  id="placeOfBirth"
                  name="placeOfBirth"
                  placeholder="Place Of Birth"
                  {...register("placeOfBirth", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        placeOfBirth: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.placeOfBirth}
                />
                <FormErrorMessage>
                  {errors.placeOfBirth && errors.placeOfBirth.message}
                </FormErrorMessage>
              </FormControl>

            

            
            </Flex>


            <Flex>
              <FormControl isInvalid={errors.nationality} m={"10px"}>
                <FormLabel htmlFor="nationality">
                Nationality
                </FormLabel>
                <Input
                  id="nationality"
                  name="nationality"
                  placeholder="Nationality"
                  {...register("nationality", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        nationality: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.nationality}
                />
                <FormErrorMessage>
                  {errors.nationality && errors.nationality.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.motherTongue} m={"10px"}>
                <FormLabel htmlFor="motherTongue">
                Mother Tongue
                </FormLabel>
                <Input
                  id="motherTongue"
                  name="motherTongue"
                  placeholder="Mother Tongue"
                  {...register("motherTongue", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        motherTongue: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.motherTongue}
                />
                <FormErrorMessage>
                  {errors.motherTongue && errors.motherTongue.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.address} m={"10px"}>
                <FormLabel htmlFor="address">
                Address
                </FormLabel>
                <Input
                  id="address"
                  name="address"
                  placeholder="Address"
                  {...register("address", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        address: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.address}
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.pinCode} m={"10px"}>
                <FormLabel htmlFor="pinCode">
                PinCode
                </FormLabel>
                <Input
                  id="pinCode"
                  name="pinCode"
                  placeholder="PinCode"
                  {...register("pinCode", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        pinCode: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.pinCode}
                />
                <FormErrorMessage>
                  {errors.pinCode && errors.pinCode.message}
                </FormErrorMessage>
              </FormControl>

              </Flex>


              <Flex>
              <FormControl isInvalid={errors.city} m={"10px"}>
                <FormLabel htmlFor="city">
                City
                </FormLabel>
                <Input
                  id="city"
                  name="city"
                  placeholder="City"
                  {...register("city", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        city: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.city}
                />
                <FormErrorMessage>
                  {errors.city && errors.city.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.mobileNo} m={"10px"}>
                <FormLabel htmlFor="mobileNo">
                Mobile No
                </FormLabel>
                <Input
                  id="mobileNo"
                  name="mobileNo"
                  placeholder="mobileNo"
                  type={"phone"}
                  {...register("mobileNo", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        mobileNo: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.mobileNo}
                />
                <FormErrorMessage>
                  {errors.mobileNo && errors.mobileNo.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email} m={"10px"}>
                <FormLabel htmlFor="email">
                Email
                </FormLabel>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type={"email"}
                  {...register("email", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        email: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.email}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.occupation} m={"10px"}>
                <FormLabel htmlFor="occupation">
                  Occupation
                </FormLabel>

                <Input
                  id="occupation"
                  name="occupation"
                  placeholder="Occupation"
                 
                  {...register("Occupation", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        occupation: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.occupation}
                />
                <FormErrorMessage>
                  {errors.occupation && errors.occupation.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.qualification} m={"10px"}>
                <FormLabel htmlFor="qualification">
                Qualification
                </FormLabel> 

                <Select
                  id="qualification"
                  name="qualification"
                  placeholder="Qualification"
                
                  {...register("qualification", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        qualification: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.qualification}
                >

{qualification.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}

                  </Select>
                
                
                <FormErrorMessage>
                  {errors.qualification && errors.qualification.message}
                </FormErrorMessage>
              </FormControl>

              </Flex>


              <Flex>
              <FormControl isInvalid={errors.whyGermanNurse} m={"10px"}>
                <FormLabel htmlFor="whyGermanNurse">
                Why do you wish to work as a nurse in germany?
                </FormLabel> 

                <Select
                  id="whyGermanNurse"
                  name="whyGermanNurse"
                  placeholder="Why do you wish to work as a nurse in germany?"
                
                  {...register("whyGermanNurse", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        whyGermanNurse: event.target.value,
                        
                      };
                    });
                  }}
                  value={userCreationFrom.whyGermanNurse}
                >

{whyGermanNurse.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}

                  </Select>
                
                
                <FormErrorMessage>
                  {errors.whyGermanNurse && errors.whyGermanNurse.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.isGermanLang} m={"10px"}>
                <FormLabel htmlFor="isGermanLang">
                Do you have previous knowledge of german language
                </FormLabel> 

                <Select
                  id="isGermanLang"
                  name="isGermanLang"
                  placeholder="Do you have previous knowledge of german language"
                
                  {...register("isGermanLang", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        isGermanLang: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.isGermanLang}
                >

       { isGermanLang.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}

                  </Select>
                
                
                <FormErrorMessage>
                  {errors.isGermanLang && errors.isGermanLang.message}
                </FormErrorMessage>
              </FormControl>


              
              <FormControl isInvalid={errors.learnGermanLang} m={"10px"}>
                <FormLabel htmlFor="learnGermanLang">
                If yes, where did you learn it
                </FormLabel> 

                <Input
                  id="learnGermanLang"
                  name="learnGermanLang"
                  
                 
                  {...register("learnGermanLang", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        learnGermanLang: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.learnGermanLang}
                />

                

                
                
                <FormErrorMessage>
                  {errors.learnGermanLang && errors.learnGermanLang.message}
                </FormErrorMessage>
              </FormControl>

              </Flex>

              <Flex>

              
              <FormControl isInvalid={errors.howComeToKnow} m={"10px"}>
                <FormLabel htmlFor="howComeToKnow">
                How Come To Know
                </FormLabel>

                <Input
                  id="howComeToKnow"
                  name="howComeToKnow"
                  placeholder="How Come To Know"
                 
                  {...register("howComeToKnow", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        howComeToKnow: event.target.value,
                        fromStatusChanged:true,
                      };
                    });
                  }}
                  value={userCreationFrom.howComeToKnow}
                />
                <FormErrorMessage>
                  {errors.howComeToKnow && errors.howComeToKnow.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl  m={"10px"}>
                <FormLabel >
                Signature
                </FormLabel>

                {userCreationFrom.signature.length >= 1 ? (
                  <Image src={userCreationFrom.signature} h={"90px"} />
                ) : (
                  <Text></Text>
                )}
               
              </FormControl>


              </Flex>

              <Box>

              <TableContainer>
  <Table size='lg'>
    <Thead key={"tbll1"}>
      <Tr key={"tbl1"}>
        <Th>Exam Passed</Th>
        <Th>School College Name</Th>
        <Th>Passing Year</Th>
        <Th>Univ Board</Th>
        <Th>Agg Percentage</Th>
      </Tr>
    </Thead>
    <Tbody>


      {qualificationLists.map((ele,index_item) => {

                        
                  return (
                    <Tr key={`${"roww_"+index_item}`}>
                    <Td>
                    <Input
                  id="examPassed"
                  name="examPassed"
                  placeholder="Exam Passed"
                  
                  onChange={(event) => {

                   
                    qualificationLists[index_item].examPassed  = event.target.value;

                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        educationFieldChanged:true,
                      };
                    });
                    
                   
                  }}
                  value={ele.examPassed}
                />
                      
                    </Td>
                    <Td>
                      
                      <Input
                  id="schoolCollegeName"
                  name="schoolCollegeName"
                  placeholder="School College Name"
                 
                  onChange={(event) => {

                   
                    qualificationLists[index_item].schoolCollegeName  = event.target.value;

                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        educationFieldChanged:true,
                      };
                    });
                    
                   
                  }}
                  value={ele.schoolCollegeName}
                />
                      </Td>
                    <Td >
                      
                    
                      <Input
                  id="passingYear"
                  name="passingYear"
                  placeholder="Passing Year"
                 
                  onChange={(event) => {

                   
                    qualificationLists[index_item].passingYear  = event.target.value;

                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        educationFieldChanged:true,
                      };
                    });
                    
                   
                  }}
                  value={ele.passingYear}
                />
                      
                      </Td>
                    <Td>
                      
                      <Input
                  id="univBoard"
                  name="univBoard"
                  placeholder="Univ Board"
                 
                  onChange={(event) => {

                   
                    qualificationLists[index_item].univBoard  = event.target.value;

                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        educationFieldChanged:true,
                      };
                    });
                    
                   
                  }}
                  value={ele.univBoard}
                />
                      
                      </Td>
                    <Td >
                    <Input
                  id="aggPercentage"
                  name="aggPercentage"
                  placeholder="Agg Percentage"
                 
                  onChange={(event) => {

                   
                    qualificationLists[index_item].aggPercentage  = event.target.value;

                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        educationFieldChanged:true,
                      };
                    });
                    
                   
                  }}
                  value={ele.aggPercentage}
                />
                    
                    </Td>
                  </Tr>
                  );
                })}
      
    </Tbody>
  </Table>
</TableContainer>

              </Box>

              

            <Flex>
              <FormControl isInvalid={errors.admFormStatusComment} m={"10px"}>
                <FormLabel htmlFor="admFormStatusComment">Status Comment</FormLabel>
                <Textarea
                  id="admFormStatusComment"
                  name="admFormStatusComment"
                  placeholder="Description"
                  {...register("admFormStatusComment", {})}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        admFormStatusComment: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.admFormStatusComment}
                />
                <FormErrorMessage>
                  {errors.admFormStatusComment && errors.admFormStatusComment.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex>
              <FormControl isInvalid={errors.iadmFormStatus} m={"10px"}>
                <FormLabel htmlFor="iadmFormStatus">From status</FormLabel>
                

                <Select
                  id="iadmFormStatus"
                  name="iadmFormStatus"
                  placeholder="From status"
                
                  {...register("iadmFormStatus", {
                   
                  })}
                  onChange={(event) => {
                    setUserCreationFrom((oldstate) => {
                      return {
                        ...oldstate,
                        iadmFormStatus: event.target.value,
                      };
                    });
                  }}
                  value={userCreationFrom.iadmFormStatus}
                >

       {  iadmFormStatus.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}

                  </Select>
            

                <FormErrorMessage>
                  {errors.user_roll_type && errors.user_roll_type.message}
                </FormErrorMessage>
              </FormControl>

            </Flex>

         
            <Flex flexDirection={"row"}>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>

              <Button mt={4} colorScheme="teal" type="button" ml={10}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
};
