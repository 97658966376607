/* eslint-disable */
import React, { useState } from "react";

import { Box, Button, Flex, Select, Image, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Textarea,
    useToast,
  } from "@chakra-ui/react";
import { ManageUserBreadCum } from "./Manageuserbreadcum";
import { PrivilegesLists } from "./PrivilegesLists";
import {createRoll,updateRoll} from "../../Helper/Courselists.helper"
import { useEffect } from "react";


export const CreateRoll = () =>{

  const toast = useToast();
 
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const editRollFromData = useSelector((state) => state.rollData);
  const accessLabel =  useSelector((state)=>state.user_access);

  //useSelector()

  //console.log("Data Details");
  //console.log(editRollFromData);

    const [userRollFrom, setuserRollFrom] = useState({

        "rollId": editRollFromData?editRollFromData.id:"0",
        "roleName": editRollFromData?editRollFromData.roleName:"",
        "roleDescription":editRollFromData?editRollFromData.roleDescription:"",
        "rollfeatures":[],

    });

    const [features,setFeatures]= useState([{

    }]);

    
    useEffect(()=>{
      setuserRollFrom((old)=>{
        return{
          ...old,
          rollfeatures:features,
        }
      })

    },[features]);



    
  const onSubmit = (values) => {
    return new Promise((resolve) => {
     
    
      
     // liveclass.student_lists = studentlists;

     if(features.length===0){

      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Select At least one role.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });


     }

      if (editRollFromData!==undefined && editRollFromData!==null && editRollFromData.id!=null) {

        
        const returnval = updateRoll(userRollFrom, reset);
      

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Role successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      } else  {

       

        
        const returnval = createRoll(userRollFrom, reset);

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Role successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  };

    return (
        <>

        <ManageUserBreadCum />

        <Box p={4} mr={18}>

        <form onSubmit={handleSubmit(onSubmit)}>

        <Flex direction={"column"}>

        <FormControl isInvalid={errors.roleName} m={"10px"}>
              <FormLabel htmlFor="roleName">Role Name</FormLabel>
              <Input
                id="roleName"
                name="roleName"
                placeholder="Name"
                {...register("roleName", {
                  required: "Please enter rol name",
                })}
                onChange={(event) => {
                    setuserRollFrom((oldstate) => {
                    return {
                      ...oldstate,
                      roleName: event.target.value,
                    };
                  });
                }}
                value={userRollFrom.roleName}
              />
              <FormErrorMessage>
                {errors.roleName && errors.roleName.message}
              </FormErrorMessage>
            </FormControl>


            <FormControl isInvalid={errors.roleName} m={"10px"}>
              <FormLabel htmlFor="roleDescription">Role Description</FormLabel>
              <Textarea
                id="roleDescription"
                name="roleDescription"
                placeholder="Role Description"
                {...register("roleDescription", {
                  required: "Please enter role description",
                })}
                onChange={(event) => {
                    setuserRollFrom((oldstate) => {
                    return {
                      ...oldstate,
                      roleDescription: event.target.value,
                    };
                  });
                }}
                value={userRollFrom.roleDescription}
              ></Textarea>
              <FormErrorMessage>
                {errors.roleDescription && errors.roleDescription.message}
              </FormErrorMessage>
            </FormControl>



        </Flex>    


        <Flex flexDirection={"row"}>
            <Button
              mt={4}
              ml={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>

            <Button
              mt={4}
              colorScheme="teal"
              ml={6}
              type="button"
            >
              Cancel
            </Button>
          </Flex>

          </form>


        <PrivilegesLists  setFeatures={setFeatures} editRollFromData={editRollFromData}></PrivilegesLists>
     
        </Box>

           
        </>
    );
}