/* eslint-disable */
import { React, useEffect, useState } from "react";
import { Box, Center, Flex, HStack, Spacer } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { ManageCourseBox } from "./ManagecourseBox";
import { ManageModuleBox } from "./ManageModuleBox";
import { ManageliveclassesBox } from "./ManageliveclassesBox";
import { ManagePaymentsBox } from "././ManagePaymentsBox";
import { ManageCertificateBox } from "./ManageCertificateBox";
import { ViewCourseProgressBox } from "./ViewCourseProgressBox";

import { ManageLession, ManageLessionBox } from "./ManageLessionBox";
import { ManageUseresBox } from "./ManageUseresBox";
import { loadAccessLabel } from "../../Helper/Courselists.helper";
import { useDispatch } from "react-redux";
import { NursingStudentBox } from "./NursingStudentBox";

export function Dashboard() {
  const navigate = useNavigate();
  const [accessLabel, setAccessLabel] = useState(null);
  const storeintoRedux = useDispatch();

  useEffect(() => {
    loadAccessLabel(setAccessLabel);
  }, []);

  useEffect(() => {
   //console.log("Access Label Value");
   // console.log(accessLabel);
    storeintoRedux({ type: "STORE_USER_ACCESS", user_access: accessLabel });

    // }
  }, [accessLabel]);

  return (
    <>
      <Box p={4}>
        {/*  Box constet start  */}

        <Center py={6}>
          <Flex direction={"column"}>
            <Flex>
              {accessLabel !== null &&
                accessLabel["pre_recorded_course"] !== null &&
                accessLabel["pre_recorded_course"]["viewPriv"] !== null &&
                accessLabel["pre_recorded_course"]["viewPriv"] === "1" && (
                  <>
                    <ManageCourseBox p="2" />
                    <Spacer p="15" />
                  </>
                )}

              {accessLabel !== null &&
                accessLabel["online_course"] !== null &&
                accessLabel["online_course"]["viewPriv"] !== null &&
                accessLabel["online_course"]["viewPriv"] === "1" && (
                  <>
                    <ManageliveclassesBox />
                    <Spacer p="15" />
                  </>
                )}

              {accessLabel !== null &&
                accessLabel["manage_payment"] !== null &&
                accessLabel["manage_payment"]["viewPriv"] !== null &&
                accessLabel["manage_payment"]["viewPriv"] === "1" && (
                  <>
                    <ManagePaymentsBox />
                    <Spacer p="15" />
                  </>
                )}

              {accessLabel !== null &&
                accessLabel["lesson"] !== null &&
                accessLabel["lesson"]["viewPriv"] !== null &&
                accessLabel["lesson"]["viewPriv"] === "1" && (
                  <>
                    <ManageLessionBox />
                  </>
                )}
            </Flex>

            <Flex mt={15} justifyContent={true}>
              {accessLabel !== null &&
                accessLabel["user"] !== null &&
                accessLabel["user"]["viewPriv"] !== null &&
                accessLabel["user"]["viewPriv"] === "1" && (
                  <>
                    <Flex>
                      <ManageUseresBox p="2" />
                      <Spacer p="15" />

                      
                        
                          <ManageCertificateBox p="2" />

                          <Spacer p="15" />
                          <ViewCourseProgressBox p="2" />

                          <Spacer p="15" />
                          
                          <NursingStudentBox />
                    
                    </Flex>
                  </>
                )}
            </Flex>
          </Flex>
        </Center>

        {/*  Box constet end  */}
      </Box>
    </>
  );
}

export default Dashboard;
