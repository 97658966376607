/* eslint-disable */
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export function ManagePaymentBreadcum({ seleted_item, courseData }) {
  const navigateField = useNavigate();
  const moduledispatcher = useDispatch();

  

  let topButtons = "";

  if (seleted_item === "liveclassess") {
    topButtons = "";
  } else {

  
    topButtons = (
      <Flex mt="4" mr="4">
        <Button
          colorScheme="teal"
          onClick={() => {
            navigateField(
              `/${
                seleted_item === "Listsaddstudentforliveclass"
                  ? "createliveclassess"
                  : "listsaddstudents"
              }`
            );
          }}
        >
          {seleted_item === "Listsaddstudentforliveclass"
            ? "Back"
            : "Add Student"}
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Flex>
        <Breadcrumb
          width="100%"
          padding={5}
          spacing="8px"
          position="sticky"
          top="0px"
          backgroundColor={"white"}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage={seleted_item === "managelessionlists"}>
            <BreadcrumbLink as={RouterLink} to="/managelessionlists">
              Lists
            </BreadcrumbLink>
          </BreadcrumbItem>
          
        </Breadcrumb>

        <Spacer />

        {topButtons}
      </Flex>
    </>
  );
}
