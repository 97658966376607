/* eslint-disable */
import { useLoginContext } from "../../context/Logincontextprovider";
import { ReactNode, useEffect, useReducer, useState } from "react";
import { Box, Button, Flex, Image, Select, toast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  Text,
  useToast,
  Spacer,
} from "@chakra-ui/react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  updateMeetingHandler,
  createMeetingHandler,
  loadSectors,
  createMeeting,
  loadUserCourseLists,
} from "../../Helper/Courselists.helper";
import Listsmodules from "../CreateModule/Listsmodules";
import { LiveclassessBreadcum } from "./LiveclassessBreadcum";
import { Listsliveclassstudents } from "./Listsliveclassstudents";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

let courseMuleLists = [];
let moduleLessionLists = [];

export const Createliveclassess = (props) => {
  //const {element} =  useLocation();
  //const { state } = useLocation();
  //let courseData = state ? JSON.parse(state) : null;

  const toast = useToast();
  const navigateField = useNavigate();
  const addStudentOrremove = useDispatch();

  
 
  let studentlists = useSelector((state) => state.meeting_student_lists);
  const courseData = useSelector((state) => state.create_meeting_form);
  
  const load_once = useSelector((state) => state.load_once);


  const createNewClassOnline = () =>{

    //console.log("This is ok ");

    
    addStudentOrremove({
      type: "add_student_into_meeting_form",
      meeting_student_lists: [],
      create_meeting_form: {},
      load_once: true,
    });

    

    
    setLiveClass({
      meeting_select_course:  "",
      meeting_select_module:  "",
      meeting_select_lession: "",
      topic_name:  "",
      topic_description:  "",
      topic_start_time:  "",
      topic_duration:  "",
      live_class_date:  "",
      student_lists:  [],
      meeting_password:  "",
      meeting_id:  "0",
      zoom_meeting_id:  "0",
      zoom_uuid:  "0",
    });


    addStudentOrremove({
      type: "add_student_into_meeting",
      meeting_student_lists: [],
    });

    addStudentOrremove({
      type: "load_once_add",
      load_once: !load_once,
    });

    




  }

  const [liveclass, setLiveClass] = useState({
    meeting_select_course: courseData ? courseData.meeting_select_course : "",
    meeting_select_module: courseData ? courseData.meeting_select_module : "",
    meeting_select_lession: courseData ? courseData.meeting_select_lession : "",
    topic_name: courseData ? courseData.topic_name : "",
    topic_description: courseData ? courseData.topic_description : "",
    topic_start_time: courseData ? courseData.topic_start_time : "",
    topic_duration: courseData ? courseData.topic_duration : "",
    live_class_date: courseData ? courseData.live_class_date : "",
    student_lists: courseData ? courseData.live_class_date : [],
    meeting_password: courseData ? courseData.meeting_password : "",
    meeting_id: courseData ? courseData.meeting_id : "0",
    zoom_meeting_id: courseData ? courseData.zoom_meeting_id : "0",
    zoom_uuid: courseData ? courseData.zoom_uuid : "0",
  });



  



  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

 
  const onSubmit = (values) => {
    return new Promise((resolve) => {
     
      
     
      liveclass.student_lists = studentlists;
      

      if (courseData && courseData.meeting_id) {

        console.log(" Course Update .... ");
        const returnval = updateMeetingHandler(liveclass, reset);
        // reset();

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Meeting  successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        const returnval = createMeetingHandler(liveclass, reset);

        if (returnval) {
          toast({
            position: "bottom-left",
            title: "Notification",
            description: "Meeting successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  };

  const [moduleAndCourseLists, setmoduleAndCourseLists] = useState({
    meetingModuleLists: [],
    meetingLessionLists: [],
    courselists: [],
    studentlists: [],
  });

  const [meetingModuleLists, setMeetingModuleLists] = useState([]);
  const [meetingLessionLists, setMeetingLessionLists] = useState([]);

  const setCourseModuleDropdown = (value) => {
    const newArray = moduleAndCourseLists.meetingModuleLists.filter(
      (element) => {
        if (element.course_id === value) {
          return true;
        } else {
          return false;
        }
      }
    );

    //console.log(newArray);

    setMeetingModuleLists(newArray);
  };

  const onSelectCourseHandler = (event) => {
    setLiveClass((oldstate) => {
      return {
        ...oldstate,
        meeting_select_course: event.target.value,
      };
    });

    // console.log(courseMuleLists);
    setCourseModuleDropdown(event.target.value);
    setMeetingLessionLists([]);
  };

  const setStudentlistsHandler = (temp_array) => {
    addStudentOrremove({
      type: "add_student_into_meeting",
      meeting_student_lists: temp_array,
    });

    setLiveClass((oldstate) => {
      return {
        ...oldstate,
        student_lists: temp_array,
      };
    });
  };

  const setMeetingLesstion = (value) => {
    const newArray = moduleAndCourseLists.meetingLessionLists.filter(
      (element) => {
        if (element.module_id === value) {
          return true;
        } else {
          return false;
        }
      }
    );

    setMeetingLessionLists(newArray);
  };

  const onModuleSelectHandler = (event) => {
    setLiveClass((oldstate) => {
      return {
        ...oldstate,
        meeting_select_module: event.target.value,
      };
    });

    setMeetingLesstion(event.target.value);
  };

  useEffect(() => {
   
    const returnvalue = loadUserCourseLists(
      setmoduleAndCourseLists,
      courseData
    );

    return () => {
      if (window.location.pathname !== "/listsaddstudents") {
       
        addStudentOrremove({
          type: "add_student_into_meeting_form",
          meeting_student_lists: [],
          create_meeting_form: {},
          load_once: true,
        });

        
      }

      
    };
  }, []);

  useEffect(() => {
    if (courseData) {
     
      setCourseModuleDropdown(courseData.meeting_select_course);
      setMeetingLesstion(courseData.meeting_select_module);

      if (load_once && moduleAndCourseLists.studentlists.length >= 1) {
        addStudentOrremove({
          type: "add_student_into_meeting",
          meeting_student_lists: moduleAndCourseLists.studentlists,
        });

        addStudentOrremove({
          type: "load_once_add",
          load_once: !load_once,
        });
      }

      setLiveClass((oldstate) => {
        return {
          ...oldstate,
          student_lists: moduleAndCourseLists.studentlists,
        };
      });
    }
  }, [moduleAndCourseLists]);

  useEffect(() => {
    
    addStudentOrremove({
      type: "create_meeting_data",
      create_meeting_form: liveclass,
    });

  }, [liveclass]);

  return (
    <>
      <LiveclassessBreadcum seleted_item="createcourse"   createNewClassOnline={createNewClassOnline} />

      <Box p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={"hidden"}
            name="meeting_id"
            value={liveclass ? liveclass.meeting_id : "0"}
          />

          <Flex>
            <FormControl isInvalid={errors.meeting_select_course} m={"10px"}>
              <FormLabel htmlFor="meeting_select_course">Course Name</FormLabel>
              <Select
                key="seletc1"
                name="meeting_select_course"
                id="meeting_select_course"
                isDisabled={courseData && Number(courseData.meeting_id)>0 ? true : false}

                placeholder="Meeting Course"
                {...register("meeting_select_course", {
                 
                  required: courseData && Number(courseData.meeting_id)>0 ? false : "Please select course",
                 
                })}

                

                onChange={onSelectCourseHandler}
                value={liveclass.meeting_select_course}
              >
                {moduleAndCourseLists.courselists.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}
              </Select>

              <FormErrorMessage>
                {errors.meeting_select_course &&
                  errors.meeting_select_course.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.meeting_select_module} m={"10px"}>
              <FormLabel htmlFor="meeting_select_module">
                Course Module
              </FormLabel>
              <Select
                key="seletc2"
                name="meeting_select_module"
                id="meeting_select_module"
                placeholder="Meeting Module"
                isDisabled={courseData && Number(courseData.meeting_id)>0 ? true : false}

                {...register("meeting_select_module", {
                  required: courseData && Number(courseData.meeting_id)>0 ? false : "Please select module",
                })}

                

                onChange={onModuleSelectHandler}
                value={liveclass.meeting_select_module}
              >
                {meetingModuleLists.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}
              </Select>

              <FormErrorMessage>
                {errors.meeting_select_module &&
                  errors.meeting_select_module.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.meeting_select_lession} m={"10px"}>
              <FormLabel htmlFor="meeting_select_lession">
                Course Lessons
              </FormLabel>
              <Select
                key="seletc2"
                name="meeting_select_lession"
                id="meeting_select_lession"
                placeholder="Meeting Lesson"
                isDisabled={courseData && Number(courseData.meeting_id)>0 ? true : false}

                {...register("meeting_select_lession", {
                  
                  required: courseData && Number(courseData.meeting_id)>0 ? false : "Please select lesson",
                })}

                

                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      meeting_select_lession: event.target.value,
                    };
                  });
                }}
                value={liveclass.meeting_select_lession}
              >
                {meetingLessionLists.map((ele) => {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.name}
                    </option>
                  );
                })}
              </Select>

              <FormErrorMessage>
                {errors.meeting_select_lession &&
                  errors.meeting_select_lession.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.topic_name} m={"10px"}>
              <FormLabel htmlFor="topic_name">Topic Name</FormLabel>
              <Input
                id="topic_name"
                name="topic_name"
                placeholder="Topic Name"
                {...register("topic_name", {
                  required: "Please enter topic name",
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      topic_name: event.target.value,
                    };
                  });
                }}
                value={liveclass.topic_name}
              />
              <FormErrorMessage>
                {errors.topic_name && errors.topic_name.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex>
            <FormControl isInvalid={errors.topic_description} m={"10px"}>
              <FormLabel htmlFor="topic_description">
                Topic Description
              </FormLabel>
              <Textarea
                id="topic_description"
                name="topic_description"
                placeholder="Topic Description"
                {...register("topic_description", {
                  required: "Please enter description",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      topic_description: event.target.value,
                    };
                  });
                }}
                value={liveclass.topic_description}
              />
              <FormErrorMessage>
                {errors.topic_description && errors.topic_description.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex flexDirection={"row"}>
            <FormControl isInvalid={errors.meeting_password} m={"10px"}>
              <FormLabel htmlFor="meeting_password">Meeting Password</FormLabel>
              <Input
                id="meeting_password"
                name="meeting_password"
                placeholder="Meeting Password"
                type="password"
                {...register("meeting_password", {})}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      meeting_password: event.target.value,
                    };
                  });
                }}
                value={liveclass.meeting_password}
              />

              <FormErrorMessage>
                {errors.meeting_password && errors.meeting_password.message}
              </FormErrorMessage>
            </FormControl>


            <FormControl isInvalid={errors.live_class_date} m={"10px"}>
              <FormLabel htmlFor="live_class_date">Live Class Date</FormLabel>
              <Input
                type="date"
                id="live_class_date"
                name="live_class_date"
                placeholder="Live Class Date"
                {...register("live_class_date", {
                  required: "Please enter live class date",
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      live_class_date: event.target.value,
                    };
                  });
                }}
                value={liveclass.live_class_date}
              />

              <FormErrorMessage>
                {errors.live_class_date && errors.live_class_date.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.topic_start_time} m={"10px"}>
              <FormLabel htmlFor="topic_start_time">Start Time</FormLabel>
              <Input
                id="topic_start_time"
                name="topic_start_time"
                placeholder="Start Time"
                type="time"
                {...register("topic_start_time", {
                  required: "Please enter start time",
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      topic_start_time: event.target.value,
                    };
                  });
                }}
                value={liveclass.topic_start_time}
              />

              <FormErrorMessage>
                {errors.topic_start_time && errors.topic_start_time.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.topic_duration} m={"10px"}>
              <FormLabel htmlFor="topic_duration">Duration (In Minutes)</FormLabel>
              <Input
                type="number"
                id="topic_duration"
                name="topic_duration"
                placeholder="Duration"
                {...register("topic_duration", {
                  required: "Please enter end time",
                })}
                onChange={(event) => {
                  setLiveClass((oldstate) => {
                    return {
                      ...oldstate,
                      topic_duration: event.target.value,
                    };
                  });
                }}
                value={liveclass.topic_duration}
              />

              <FormErrorMessage>
                {errors.topic_duration && errors.topic_duration.message}
              </FormErrorMessage>
            </FormControl>

            
          </Flex>

          <Flex flexDirection={"row"}>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>

      <Box>
        {studentlists && (
          <Listsliveclassstudents
            studentlists={studentlists}
            setStudent={setStudentlistsHandler}
          />
        )}
      </Box>
    </>
  );
};
