/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";

import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { fetchUserLists, fetchStudentLists } from "../../Helper/Courselists.helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManageUserBreadCum } from "./ManagestudentBreadcum";
import Moment from "react-moment";

export const StudentFromSubmitLists = () => {
  const [userlists, setUserLists] = useState(null);
  const [searchBytext, setsearchBytext] = useState("");
  const storeDispacher = useDispatch();
  const toast = useToast();
  const accessLabel = useSelector((state) => state.user_access);

  const navigate = useNavigate();

  useEffect(() => {
    fetchStudentLists(setUserLists);
  }, []);

  return (
    <>
      <ManageUserBreadCum />

      <Box p={4}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Student Name</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              <Th>Course name</Th>
              <Th>Submission Date</Th>
              <Th>Form Status</Th>
              <Th>Status Date</Th>
              <Th>Status Comment</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userlists !== null &&
              Number.parseInt(userlists.length) >= 1 &&
              userlists.map((element, array_index) => {

                const index_item_record =  "new_rows_"+array_index;
                var itemname  =  "Admission Form under Review";

                if(Number(element.iadmFormStatus)===1)
                itemname  =  "Admission Form Approved";
                if(Number(element.iadmFormStatus)===2)
                itemname  =  "Admission Form Rejected";

                if(element.iadmFormStatus!=null &&  Number(element.iadmFormStatus)===3)
                itemname  =  "Admission Form Re-Submitted";

              

              
                return (
                  <Tr key={index_item_record}>
                    <Td>
                      <Text>{element.name} {Number(element.iadmFormStatus)}</Text>
                    </Td>
                    <Td>
                      <Text>{element.email}</Text>
                    </Td>
                    
                    <Td>
                      <Text>{element.mobileNo}</Text>
                    </Td>
                    <Td>
                      <Text>{element.course_name}</Text>
                    </Td>

                    
                    
                    <Td>
                      <Text>
                      <Moment format="HH:mm A DD/MM/YYYY">
                      {element.createDate}
                        </Moment>
                       </Text>
                    </Td>
                    
                    <Td>
                      <Text>{itemname}</Text>
                    </Td>
                    
                    <Td>
                      <Text>
                      <Moment format="HH:mm A DD/MM/YYYY">
                      {element.admFormStatusDate}
                        </Moment>
                      

                      </Text>
                    </Td>
                    
                    <Td>
                      <Text>{element.admFormStatusComment}</Text>
                    </Td>
                    
                    
                    <Td>
                      <HStack mt={"10px"}>
                        {accessLabel !== null &&
                          accessLabel["user"] !== null &&
                          accessLabel["user"]["editPriv"] !== null &&
                          accessLabel["user"]["editPriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="View Admission From"
                              icon={<EditIcon />}
                              onClick={() => {
                                // storeDispacher()

                                storeDispacher({
                                  type: "save_nursingstudent_register",
                                  nurSingStudents: element,
                                });

                                navigate("/editUserSubmitedFrom", {
                                  state: "",
                                });
                              }}
                              // mx={2}
                            />
                          )}

                        
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
