/* eslint-disable */
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
  } from '@chakra-ui/react';
  import { useEffect, useState, useRef  } from "react";
export const Customalertyesno = (props) => {
    const cancelRef = useRef()
    
  
    return (
      <>
        <AlertDialog
          isOpen={props.show}
          leastDestructiveRef={cancelRef}
          onClose={props.cancelAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
               {props.title}
              </AlertDialogHeader>
  
              <AlertDialogBody>
              {props.description}
              </AlertDialogBody>
  
              <AlertDialogFooter>
              <Button colorScheme='red' onClick={props.deleteAlert} >
                  Yes
                </Button>
                <Button ref={cancelRef} onClick={props.cancelAlert} ml={3}>
                  No
                </Button>
               
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }