/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ChevronRightIcon, QuestionIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

export const ManageUserBreadCum = (props) => {

 const accessLabel =  useSelector((state)=>state.user_access);


  
  const [showpageButton, setshowpageButton] = useState(true);
  const [showbackbutton, setshowbackbutton] = useState(true);
  const [manageRollButton, setmanageRollButton] = useState(false);

  useEffect(() => {
    setshowbackbutton(false);
    if (window.location.pathname === "/createuseres") {
      setshowpageButton(true);
    } else if (window.location.pathname === "/listsusers") {
      setshowpageButton(false);
      setmanageRollButton(true);
    } else if (window.location.pathname === "/manageroll") {
      setshowpageButton(false);
      setmanageRollButton(false);
      setshowbackbutton(false);
    } else {
      setshowbackbutton(true);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <Flex>
      <Breadcrumb
        width="100%"
        padding={5}
        spacing="8px"
        position="sticky"
        top="0px"
        backgroundColor={"white"}
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        {accessLabel !== null &&
  accessLabel["user"] !== null &&
  accessLabel["user"]["viewPriv"] !== null &&
  accessLabel["user"]["viewPriv"] === "1" && (
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/studentFromSubmitLists">
            Manage Records
          </BreadcrumbLink>
        </BreadcrumbItem>
  )}

    


      </Breadcrumb>

      

      
    </Flex>
  );
};
