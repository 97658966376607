/* eslint-disable */
import React, { useEffect, useState } from "react";
import { CustomAlert } from "../../component/Customalert";
import {
  Box,
  HStack,
  Text,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spacer,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import {
  SearchIcon,
  
} from "@chakra-ui/icons";
import {
  loadLessionForModule,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

export const LessionListsForModule = (props) => {
  const navigate = useNavigate();

  const [searchText, setsearchText] = useState("");
  const [showdialog, setShowdialog] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const cancelAlertHandler = () => {};
  const deleteAlertHandler = () => {};

  const searchBytext = (item) => {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) 
    );
  };

  const [listsLession, setListsLession] = useState([]);

  useEffect(() => {
    loadLessionForModule(setListsLession, props.courseType, props);
  }, []);

  
   
  return (
    <>
      <Flex flexDirection={"columns"}>
        <Button
          mt={4}
          colorScheme="teal"
          type="button"
          onClick={() => {
            navigate("/createlession", {
              state: JSON.stringify({"refere_from":"createmodule"}),
            });
          }}
        >
          Add Live
        </Button>
        <Spacer />

        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setcourseLsists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <CustomAlert
          show={showdialog.show}
          title={showdialog.title}
          description={showdialog.description}
          cancelAlert={cancelAlertHandler}
          deleteAlert={deleteAlertHandler}
        />
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Lesson Type</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(listsLession.length) >= 1 &&
              listsLession.filter(searchBytext).map((element) => { 
                
               // const isChecke = defaultIsChecked;

               
               
          

                return (
                <Tr key={element.id}>
                  <Td>
                    <Text>{element.name}</Text>
                  </Td>
                  <Td>
                    <Text>{element.lessonDescription}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {element.lessonMime === "2" ? "Live" : "Prerecorded"}
                    </Text>
                  </Td>
                  <Td>
                    <HStack mt={"10px"}>
                      <Checkbox
                        colorScheme="green"
                        value={element.id}
                        defaultChecked={props.isItemChecked(element.id)} 
                        onChange={(event) => {
                          props.updateModuleLesson(event.target.value);
                        }}
                      >
                       
                      </Checkbox>
                    </HStack>
                  </Td>
                </Tr>
              )
              
                      })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
