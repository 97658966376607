/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
} from "@chakra-ui/react";

import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { fetchManagerollRolls, fetchUserLists } from "../../Helper/Courselists.helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManageUserBreadCum } from "./Manageuserbreadcum";

export const Manageroll = () => {
  const [rollLists, setrollLists] = useState(null);
  const [searchBytext, setsearchBytext] = useState("");
  const storeDispacher = useDispatch();
  const toast = useToast();
  const accessLabel =  useSelector((state)=>state.user_access);
  


  const navigate = useNavigate();

  useEffect(() => {
    fetchManagerollRolls(setrollLists);
    
  }, []);

  return (
    <>
     <ManageUserBreadCum />
     
      <Box p={4}>
        <Table size="sm">
          <Thead>
            <Tr key={"table1"}>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rollLists !== null &&
              Number.parseInt(rollLists.length) >= 1 &&
              rollLists.map((element, array_index) => {
                var newKey = "main_key_"+array_index;
                return (
                  <Tr key={newKey}>
                    <Td>
                      <Text>{element.roleName}</Text>
                    </Td>
                    <Td>
                      <Text>{element.roleDescription}</Text>
                    </Td>
                   
                    <Td>
                       
                    <HStack mt={"10px"}>
                    {accessLabel !== null &&
                accessLabel["role"] !== null &&
                accessLabel["role"]["editPriv"] !== null && accessLabel["role"]["editPriv"] === "1"  && 
                     
                
                <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Edit Course"
                        icon={<EditIcon />}
                        onClick={() => {

                          storeDispacher({type:"save_roll_date",rollData: element });

                          navigate("/createroll", {
                            state: '',
                          });
            
          
                          
                        }}
                        // mx={2}
                      />

              }

{accessLabel !== null &&
                accessLabel["role"] !== null &&
                accessLabel["role"]["deletePriv"] !== null && accessLabel["role"]["deletePriv"] === "1"  && 
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Delete Course"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          
                        }}
                        // mx={2}
                      />

              }


{accessLabel !== null &&
                accessLabel["role"] !== null &&
                accessLabel["role"]["disablePriv"] !== null && accessLabel["role"]["disablePriv"] === "1"  && 
                     
                      <IconButton
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Inactive Course"
                        onClick={() => {
                          
                        }}
                        icon={
                          Number.parseInt(element.isActive) === 1 ? (
                            <ViewOffIcon />
                          ) : (
                            <ViewIcon />
                          )
                        }
                        // mx={2}
                      />

              }

                      
                    </HStack>

                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
