/* eslint-disable */
import { createStore } from "redux";

const storeCourseReducer = (
  state = {
    course_data: {},
    module_data: {},
    meeting_student_lists: [],
    create_meeting_form: {},
    lesson_data:{},
    load_once: true,
    userData:{},
  },
  action
) => {
  if (action.type === "save_course_data") {
    return {
      ...state,
      course_data: action.course_data,
    };
  }

  if (action.type === "save_nursingstudent_register") {
    return {
      ...state,
      nurSingStudents: action.nurSingStudents,
    };
  }

  if (action.type === "certificateData") {
    return {
      ...state,
      certificateData: action.certificateData,
    };
  }


  


  if (action.type === "STORE_USER_ACCESS") {
    return {
      ...state,
      user_access: action.user_access,
    };
  }


  if (action.type === "save_user_date") {
    return {
      ...state,
      userData: action.userData,
    };
  }


  if (action.type === "save_roll_date") {
    return {
      ...state,
      rollData: action.rollData,
    };
  }

  if (action.type === "add_student_into_meeting") {
    return {
      ...state,
      meeting_student_lists: action.meeting_student_lists,
    };
  }
  if (action.type === "add_student_into_meeting_form") {
    return {
      ...state,
      meeting_student_lists: action.meeting_student_lists,
      create_meeting_form: action.create_meeting_form,
      load_once: action.load_once,
    };
  }

  if (action.type === "load_once_add") {
    return {
      ...state,
      load_once: action.load_once,
    };
  }


  if (action.type === "save_lesson") {
    return {
      ...state,
      lesson_data: action.lesson_data,
    };
  }

  if (action.type === "store_module_data") {
    return {
      ...state,
      module_data: action.module_data,
    };
  }

  if (action.type === "remove_course_data") {
    return {
      course_data: {},
    };
  }

  if (action.type === "create_meeting_data") {
    return {
      ...state,
      create_meeting_form: action.create_meeting_form,
    };
  }

  return state;
};

export const store = createStore(storeCourseReducer);
