/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
  Button,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
  CopyIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import Moment from "react-moment";

import {
  loadLivemeetings,
  inactiVeMeeting,
  deleteCourse,
  timeDistance,
  copyMeetingHandler,
  deleteMeeting,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlertDialog } from "../../component/Customalert.dialog";
import { CustomAlert } from "../../component/Customalert";
import { Customalertyesno } from "../../component/Customalertyesno";

import { useDispatch,useSelector } from "react-redux";
import { LiveclassessBreadcum } from "./LiveclassessBreadcum";


export function Listsliveclassess() {
  const perPageConstant = 50;
  const [liveLessionLists, setliveLessionLists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
  const [reloadtable, setReloadtable] = useState(true);

  const [indexCourse, setindexCourse] = useState(-1);
  const storeDispacher = useDispatch();
  const accessLabel = useSelector((state) => state.user_access);
  

  const toast = useToast();

  const navigate = useNavigate();

  const [showdialog, setShowdialog] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const [showdialogyesno, setShowdialogYesNo] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const cancelAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });
  };

  const deleteAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });

    if (true) {
      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Meeting copyed successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const searchBytext = (item) => {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return (
      searchPattern.test(item.name) ||
      searchPattern.test(item.courseAuthor) ||
      searchPattern.test(item.sectorName)
    );
  };

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  useEffect(() => {
    loadLivemeetings(setliveLessionLists);
  }, [reloadtable]);

  return (
    <>
      <Flex flexDirection={"columns"}>
        <LiveclassessBreadcum seleted_item="liveclassess" />

        <Spacer />
        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setliveLessionLists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <CustomAlert
          show={showdialog.show}
          title={showdialog.title}
          description={showdialog.description}
          cancelAlert={() => {
            setShowdialog((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });
          }}
          deleteAlert={() => {
            setShowdialog((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });

            const temp_array = [...liveLessionLists];
            const itemDeleted = deleteMeeting(
              setliveLessionLists,
              temp_array,
              showdialog.array_position
            );
            if (itemDeleted) {
              toast({
                position: "bottom-left",
                title: "Notification",
                description: "Meeting deleted successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });

              setReloadtable(!reloadtable);
            }

            // setliveLessionLists(tem_array);

            /*  title: '',
           description: '',
           store_data:null,
           array_position:null,*/
          }}
        />
        <Customalertyesno
          show={showdialogyesno.show}
          title={showdialogyesno.title}
          description={showdialogyesno.description}
          cancelAlert={() => {
            setShowdialogYesNo((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });
          }}
          deleteAlert={() => {
            setShowdialogYesNo((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });

            if (
              copyMeetingHandler(
                showdialogyesno.store_data,
                setliveLessionLists
              )
            ) {
              toast({
                position: "bottom-left",
                title: "Notification",
                description: "Meeting copyed successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              setReloadtable(!reloadtable);
            }

            // setliveLessionLists([...liveLessionLists,showdialogyesno.store_data])
          }}
        />

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Class Name</Th>
              <Th>Teacher Name</Th>
              <Th>Topic</Th>
              <Th>Start Time</Th>
              <Th>End Time</Th>
              <Th>Duration</Th>
              <Th>Total Participant</Th>
              <Th>*</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(liveLessionLists.length) >= 1 &&
              liveLessionLists.filter(searchBytext).map((element, index) => {
                //  const start_date = Date.parse(element.topic_start_time);
                //  const end_date = Date.parse(element.topic_duration);

                //  const date_diff = timeDistance(end_date, start_date);

                const start_time_1 = Date.parse(
                  element.live_class_date + " " + element.topic_start_time
                );

                return (
                  <Tr key={index}>
                    <Td>
                      <Text>{element.class_name}</Text>
                    </Td>
                    <Td>
                      <Text>{element.userFullname}</Text>
                    </Td>
                    <Td>
                      <Text>{element.topic_name}</Text>
                    </Td>
                    <Td>
                      <Text>
                        <Moment format="HH:mm A DD/MM/YYYY">
                          {start_time_1}
                        </Moment>
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        <Moment
                          add={{ minutes: element.topic_duration }}
                          format="HH:mm A DD/MM/YYYY"
                        >
                          {start_time_1}
                        </Moment>
                      </Text>
                    </Td>

                    <Td>
                      <Text> {element.topic_duration}</Text>
                    </Td>
                    <Td>
                      <Text>{element.total_student}</Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        size="sm"
                        onClick={() => {
                          const newWindow = window.open(
                            element.zoom_start_url,
                            "_blank",
                            "noopener,noreferrer"
                          );
                          if (newWindow) newWindow.opener = null;
                        }}
                      >
                        Start Meeting
                      </Button>
                    </Td>

                    <Td>
                      <HStack mt={"10px"}>
                        {accessLabel !== null &&
                          accessLabel["online_course"] !== null &&
                          accessLabel["online_course"]["editPriv"] !== null &&
                          accessLabel["online_course"]["editPriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Edit Meeting"
                              icon={<EditIcon />}
                              onClick={() => {
                                if (true) {
                                  const data_object = liveLessionLists[index];

                                  const liveclass = {
                                    meeting_select_course: data_object
                                      ? data_object.meeting_select_course
                                      : "",
                                    meeting_select_module: data_object
                                      ? data_object.meeting_select_module
                                      : "",
                                    meeting_select_lession: data_object
                                      ? data_object.meeting_select_lession
                                      : "",
                                    topic_name: data_object
                                      ? data_object.topic_name
                                      : "",
                                    topic_description: data_object
                                      ? data_object.topic_description
                                      : "",
                                    topic_start_time: data_object
                                      ? data_object.topic_start_time
                                      : "",
                                    topic_duration: data_object
                                      ? data_object.topic_duration
                                      : "",
                                    live_class_date: data_object
                                      ? data_object.live_class_date
                                      : "",
                                    meeting_password: data_object
                                      ? data_object.meeting_password
                                      : "",
                                    meeting_id: data_object
                                      ? data_object.meeting_id
                                      : "0",
                                    student_lists: [],
                                    zoom_meeting_id: data_object
                                      ? data_object.zoom_meeting_id
                                      : "0",
                                    zoom_uuid: data_object
                                      ? data_object.zoom_uuid
                                      : "0",
                                  };

                                  storeDispacher({
                                    type: "create_meeting_data",
                                    create_meeting_form: liveclass,
                                  });

                                  navigate("/editliveclassess", {
                                    state: JSON.stringify(data_object),
                                  });
                                }
                              }}
                              // mx={2}
                            />
                          )}

                        {accessLabel !== null &&
                          accessLabel["online_course"] !== null &&
                          accessLabel["online_course"]["deletePriv"] !== null &&
                          accessLabel["online_course"]["deletePriv"] ===
                            "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Delete Meeting"
                              icon={<DeleteIcon />}
                              onClick={() => {
                                if (true) {
                                  //  setindexCourse(array_position);
                                  //  setshowdialog(!showdialog);

                                  setShowdialog((oldState) => {
                                    return {
                                      ...oldState,
                                      show: true,
                                      store_data: element,
                                      array_position: index,
                                      title: "Delete Item Notification",
                                      description:
                                        "Do you want to delete this meeting?",
                                    };
                                  });
                                }
                              }}
                              // mx={2}
                            />
                          )}

                        {accessLabel !== null &&
                          accessLabel["online_course"] !== null &&
                          accessLabel["online_course"]["disablePriv"] !==
                            null &&
                          accessLabel["online_course"]["disablePriv"] ===
                            "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Inactive Meeting"
                              onClick={() => {
                                let temp_array = [...liveLessionLists];

                                // console.log("<" + index + ">");

                                // setliveLessionLists(temp_array);

                                const responseMessage = inactiVeMeeting(
                                  setliveLessionLists,
                                  temp_array,
                                  index
                                );
                                if (responseMessage) {
                                  toast({
                                    position: "bottom-left",
                                    title: "Notification",
                                    description:
                                      Number.parseInt(element.isActive) === 1
                                        ? "Meeting Inactive successfully"
                                        : "Meeting activated successfully",
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                                  });
                                }
                              }}
                              icon={
                                Number.parseInt(element.isActive) === 1 ? (
                                  <ViewOffIcon />
                                ) : (
                                  <ViewIcon />
                                )
                              }
                              // mx={2}
                            />
                          )}

                        <IconButton
                          variant="outline"
                          colorScheme="teal"
                          aria-label="Copy Icon"
                          icon={<CopyIcon />}
                          onClick={() => {
                            if (true) {
                              setShowdialogYesNo((oldState) => {
                                return {
                                  ...oldState,
                                  show: true,
                                  store_data: element,
                                  array_position: index,
                                  description:
                                    "Do you want to copy live classes?",
                                  title: "Copy the meetings",
                                };
                              });
                            }
                          }}
                          // mx={2}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      {(liveLessionLists.filter(searchBytext).length > perPageConstant) &
      false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={liveLessionLists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
}
