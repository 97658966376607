import React from "react";
import Loginroutes from "./routes/Loginroutes";
import { Provider } from "react-redux";
import {store} from './store/StoreRedux'
const App = () => {
  return (
    <Provider store={store}>
      <Loginroutes />
    </Provider>
  );
};

export default App;
