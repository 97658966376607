/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Center,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
  Button,
  Link,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  StarIcon,
  PlusSquareIcon,
  CopyIcon,
} from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import Moment from "react-moment";

import {
  copyMeetingHandler,
  loadLessionLists,
  inactiVeLesson,
  deleteLesson,
} from "../../Helper/Courselists.helper";
import { useNavigate } from "react-router-dom";

import { CustomAlertDialog } from "../../component/Customalert.dialog";
import { CustomAlert } from "../../component/Customalert";
import { Customalertyesno } from "../../component/Customalertyesno";

import { useDispatch, useSelector } from "react-redux";
import { ManageLessionBreadcum } from "./ManageLessionBreadcum";

export const Managelession = () => {
  const perPageConstant = 50;
  const [liveLessionLists, setliveLessionLists] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [perPage, setperPage] = useState(perPageConstant);
  const [pageStart, setpageStart] = useState(0);
  const [reloadtable, setReloadtable] = useState(true);

  const [indexCourse, setindexCourse] = useState(-1);
  const storeDispacher = useDispatch();

  const toast = useToast();

  const navigate = useNavigate();
  const accessLabel = useSelector((state) => state.user_access);

  const [showdialog, setShowdialog] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const [showdialogyesno, setShowdialogYesNo] = useState({
    show: false,
    title: "",
    description: "",
    store_data: null,
    array_position: null,
  });

  const cancelAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });
  };

  const deleteAlertHandler = () => {
    setShowdialog((oldState) => {
      return {
        ...oldState,
        show: false,
      };
    });

    if (true) {
      toast({
        position: "bottom-left",
        title: "Notification",
        description: "Lesson copyed successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const searchBytext = (item) => {
    var searchPattern = new RegExp("^" + searchText, "i", "g");
    return searchPattern.test(item.name) || searchPattern.test(item.courseType);
  };

  const findSeletedItemPosition = (temp_array, element) => {
    let array_position = 0;

    temp_array.filter((el, ind) => {
      if (Number.parseInt(el.id) === Number.parseInt(element.id)) {
        array_position = ind;
      }
    });

    return array_position;
  };

  useEffect(() => {
    loadLessionLists(setliveLessionLists);
  }, [reloadtable]);

  return (
    <>
      <Flex flexDirection={"columns"}>
        <ManageLessionBreadcum seleted_item="liveclassess" />

        <Spacer />
        <InputGroup mt={"15px"} mr={"20px"} width={"40vw"}>
          <Input
            placeholder="Search"
            onChange={(event) => {
              setsearchText(event.target.value);
            }}
          />
          <InputRightElement
            children={
              <SearchIcon
                color="green.500"
                onClick={() => {
                  // loadCourseLists(setliveLessionLists, searchText);
                  //setsearchText();
                }}
              />
            }
          />
        </InputGroup>
      </Flex>
      <Box p={4}>
        <CustomAlert
          show={showdialog.show}
          title={showdialog.title}
          description={showdialog.description}
          cancelAlert={() => {
            setShowdialog((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });
          }}
          deleteAlert={() => {
            setShowdialog((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });

            const temp_array = [...liveLessionLists];
            const itemDeleted = deleteLesson(
              setliveLessionLists,
              temp_array,
              showdialog.array_position
            );
            if (itemDeleted) {
              toast({
                position: "bottom-left",
                title: "Notification",
                description: "Lesson deleted successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });

              setReloadtable(!reloadtable);
            }

            // setliveLessionLists(tem_array);

            /*  title: '',
           description: '',
           store_data:null,
           array_position:null,*/
          }}
        />
        <Customalertyesno
          show={showdialogyesno.show}
          title={showdialogyesno.title}
          description={showdialogyesno.description}
          cancelAlert={() => {
            setShowdialogYesNo((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });
          }}
          deleteAlert={() => {
            setShowdialogYesNo((oldState) => {
              return {
                ...oldState,
                show: false,
              };
            });

            if (
              copyMeetingHandler(
                showdialogyesno.store_data,
                setliveLessionLists
              )
            ) {
              toast({
                position: "bottom-left",
                title: "Notification",
                description: "Lesson copyed successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              setReloadtable(!reloadtable);
            }

            // setliveLessionLists([...liveLessionLists,showdialogyesno.store_data])
          }}
        />

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Lesson Name</Th>
              <Th>Lesson Description</Th>
              <Th>Content</Th>
              <Th>Lesson Type</Th>
              <Th>Created Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Number.parseInt(liveLessionLists.length) >= 1 &&
              liveLessionLists.filter(searchBytext).map((element, index) => {
                //  const start_date = Date.parse(element.topic_start_time);
                //  const end_date = Date.parse(element.topic_duration);

                //  const date_diff = timeDistance(end_date, start_date);

                const start_time_1 = Date.parse(
                  element.live_class_date + " " + element.topic_start_time
                );

                return (
                  <Tr key={index}>
                    <Td>
                      <Text isTruncated w={40}>
                        {element.name}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{element.lessonDescription}</Text>
                    </Td>
                    <Td>
                      {element.lessonContent && (
                        <Link href={element.lessonContent} target="_blank">
                          View
                        </Link>
                      )}
                    </Td>
                    <Td>
                      <Text>{element.courseType}</Text>
                    </Td>
                    <Td>
                      <Text>
                        <Moment format="HH:mm A DD/MM/YYYY">
                          {element.createdDate}
                        </Moment>
                      </Text>
                    </Td>

                    <Td>
                      <HStack mt={"10px"}>
                        {accessLabel !== null &&
                          accessLabel["lesson"] !== null &&
                          accessLabel["lesson"]["editPriv"] !== null &&
                          accessLabel["lesson"]["editPriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Edit Course"
                              icon={<EditIcon />}
                              onClick={() => {
                                if (true) {
                                  const data_object = liveLessionLists[index];

                                  storeDispacher({
                                    type: "save_lesson",
                                    lesson_data: data_object,
                                  });

                                  navigate("/createlession", {
                                    state: JSON.stringify(data_object),
                                  });
                                }
                              }}
                              // mx={2}
                            />
                          )}

                        {accessLabel !== null &&
                          accessLabel["lesson"] !== null &&
                          accessLabel["lesson"]["deletePriv"] !== null &&
                          accessLabel["lesson"]["deletePriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Delete Lesson"
                              icon={<DeleteIcon />}
                              onClick={() => {
                                if (true) {
                                  //  setindexCourse(array_position);
                                  //  setshowdialog(!showdialog);

                                  setShowdialog((oldState) => {
                                    return {
                                      ...oldState,
                                      show: true,
                                      store_data: element,
                                      array_position: index,
                                      title: "Delete Item Notification",
                                      description:
                                        "Do you want to delete this lesson?",
                                    };
                                  });
                                }
                              }}
                              // mx={2}
                            />
                          )}

                        {accessLabel !== null &&
                          accessLabel["lesson"] !== null &&
                          accessLabel["lesson"]["disablePriv"] !== null &&
                          accessLabel["lesson"]["disablePriv"] === "1" && (
                            <IconButton
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Inactive Lesson"
                              onClick={() => {
                                let temp_array = [...liveLessionLists];

                                const responseMessage = inactiVeLesson(
                                  setliveLessionLists,
                                  temp_array,
                                  index
                                );
                                if (responseMessage) {
                                  toast({
                                    position: "bottom-left",
                                    title: "Notification",
                                    description:
                                      Number.parseInt(element.isActive) === 1
                                        ? "Lesson Inactive successfully"
                                        : "Lesson activated successfully",
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                                  });
                                }
                              }}
                              icon={
                                Number.parseInt(element.isActive) === 1 ? (
                                  <ViewOffIcon />
                                ) : (
                                  <ViewIcon />
                                )
                              }
                              // mx={2}
                            />
                          )}
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      {(liveLessionLists.filter(searchBytext).length > perPageConstant) &
      false ? (
        <Flex
          w="full"
          bg={"gray"}
          pt={2}
          pb={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Pagination
            key={0}
            defaultCurrent={1}
            pageSize={perPage}
            total={liveLessionLists.filter(searchBytext).length}
            paginationProps={{ display: "flex", mb: 4 }}
            pageNeighbours={1}
            size={"sm"}
            onChange={(page) => {
              setpageStart(perPageConstant * page - perPageConstant);
              setperPage(perPageConstant * page);
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};
